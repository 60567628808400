import React, { useEffect, useState } from 'react';
import { carInitialState } from '../../../../services/initialStates';
import { carsCollectionRef } from '../../../../services/collectionsRef';
import { FireDB } from '../../../../server/firebase-config';
import { addDoc, collection, onSnapshot, orderBy, query, serverTimestamp } from 'firebase/firestore';
import { AiOutlinePlusSquare  } from 'react-icons/ai';
import { todayDate } from '../../../../services/todayDate';

const AddNewCar = () => {
    const [car, setCar] = useState(carInitialState);
    const [ carNum, setCarNum ] = useState('');
    const [cars, setCars] = useState([]);
    const [error, setError] = useState(false);
    const [carBrands, setCarBrands] = useState([]);

    const inputChange = (event) => {
        const value = event.target.value;
        setCar({
            ...car, [ event.target.name ] : value
        });
    };

    useEffect(() => {
        const fetchCarsData = query(carsCollectionRef, orderBy('timestamp', 'asc'));
        const onsub = onSnapshot(fetchCarsData, (snapshot) => {
            setCars(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        });
        onsub;
    }, []);

    useEffect(() => {
        if (cars.length > 0){
            let carNumber = [];
            const lastCar = cars.pop();
            const lastCarIdNumber = lastCar.carIdNr;
            carNumber.push(lastCarIdNumber);
            setCarNum(Number (carNumber) + 1);
        } else{
            setCarNum(1);
        }
    }, [cars]);

    useEffect(() => {
        const carBrandCollectionRef = collection(FireDB, 'carBrands');
        const fetchCarsData = query(carBrandCollectionRef, orderBy('timestamp', 'asc'));
        const onsub = onSnapshot(fetchCarsData, (snapshot) => {
            setCarBrands(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        });
        onsub;
    }, []);

    const handleAddNewCar = async (event) => {
        event.preventDefault();
        if(carNum === 0){
            return setError('Klaida .');
        }

        if(car.brand === '') {
            setError('Pasirinkite auto marke !');
            setTimeout(() => {
                setError('');
            }, 2000);
        } else{
            const newCar = {
                carIdNr: carNum,
                brand : car.brand,
                model: car.model || 'nenustatyta',
                year: car.year || 2000,
                carRegNr: car.regNr || 'nenustatyta',
                chassiNr: car.chassiNr || 'nenustatyta',
                fuelType: car.fuelType || 'nenustatyta',
                engineWorkingVolume: car.engineWorkingVolume || 'nenustatyta',
                engineCode: car.engineCode || 'nenustatyta',
                engineKw: car.kw || 0,
                gearBoxType: car.gearBoxType || 'nenustatyta',
                gearBoxCode: car.gearBoxCode || 'nenustatyta',
                carColor: car.color || 'nenustatyta',
                steeringWheelPosition: car.steeringWheelPosition || 'nenustatyta',
                addedInfo: car.addedInfo || 'nenustatyta',
                carPrice: car.price || 0,
                priceInCurrency: car.currency || 'nenustatyta',
                weight: car.weight || 0,
                km: car.km || 0,
                engineWorking: car.working || 'nenustatyta',
                carCustomer: car.customer || 'nenustatyta',
                carComments: car.comments || 'nenustatyta',
                carStatus: car.status || 'nenustatyta',
                timestamp: serverTimestamp(),
                date: todayDate(),
                update: '',
                showDeleteButton: false
            };
            await addDoc(carsCollectionRef, newCar);
            setCar(carInitialState);
        }
    };

    const sortCarBrands = carBrands && carBrands.sort( (a, b) => a.brand.localeCompare(b.brand) );
   
    return (
        <div className='add-new-car-form'>
            <h1>Pridėti automobilį</h1>
            {error && <span>{error}</span>}
            <form onSubmit={handleAddNewCar}>
                <select id='brand' name='brand' className='add-form-select' value={car.brand} onChange={inputChange} style={{ textTransform: 'capitalize'}}>
                    <option value=''>Pasirinkite marke</option>
                    {
                        carBrands && sortCarBrands.map((car) => (
                            <option value={car.brand} key={car.id} >{car.brand}</option>
                        ))
                    }
                </select>
                <button type='submit'>
                    <AiOutlinePlusSquare  className='icon'/>
                </button>
            </form>            
        </div>
    );
};

export default AddNewCar;