export const ROUTES = {
    PUBLIC_DEFAULT: '/',
    PUBLIC_LOGIN: 'login',
    PUBLIC_REGISTER: 'x8?register!w5w',
    PAGE_NOT_FOUND: '*',

    DASHBOARD: 'dashboard',
    
    DASHBOARD_CARS: 'cars',
    DASHBOARD_CAR_PAGE: 'cars/car_page/:id',
    DASHBOARD_UPDATE_CAR: 'cars/update_car/:id',
    DASHBOARD_CAR_PARTS: 'cars/car_parts/:id',
    DASHBOARD_CAR_PARTS_COLLECTION: 'cars/car_parts_collection/:id',
    DASHBOARD_CAR_PART_INFO: 'cars/car_part_info/:id/:partid',
    DASHBOARD_CAR_PARTS_ORDER: 'cars/car_parts_order/:id',
    DASHBOARD_CAR_ORDERED_PARTS: 'cars/car_ordered_parts/:id',
    DASHBOARD_CAR_PACKINGLIST: 'cars/packinglist/:id',

    DASHBOARD_PACKINGLIST: 'packinglists',
    DASHBOARD_UPDATE_PACKINGLIST: 'packinglists/update_packinglist/:id',
    DASHBOARD_ADD_PARTS_TO_PACKINGLIST: 'packinglists/add_parts/:id',
    DASHBOARD_PACKINGLIST_PRINT_SAVED_PARTS: 'packinglists/print_saved_car_parts/:id',

    DASHBOARD_SETTINGS: 'settings',
};