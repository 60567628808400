import React from "react";
// import AddModel from "./AddModel";
import { MdDelete } from "react-icons/md";

const PartsList = ({ part, handleDeleteCarPart }) => {
  return (
    <div
      key={part.id}
      style={{
        border: "1px solid gray",
        margin: "5px 0",
        padding: "5px",
        height: "100%",
        borderRadius: "5px",
        boxShadow:
          "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
      }}
    >
      <span
        style={{
          textTransform: "capitalize",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {part.name}{" "}
        <button
          type='button'
          style={{
            border: "none",
            backgroundColor: "white",
            color: "red",
            cursor: "pointer",
          }}
          onClick={() => handleDeleteCarPart(part.id)}
        >
          <MdDelete />
        </button>
      </span>
      <br />
      {/* <AddModel brand={part} /> */}
    </div>
  );
};

export default PartsList;
