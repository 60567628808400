import React, { useEffect, useState } from 'react';
import { useUserContext } from '../../../context/UserAuthContext';
import { FireDB } from '../../../server/firebase-config';
import { deleteDoc, doc, onSnapshot, orderBy, query } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import * as FaIcons from 'react-icons/fa';
import ReactPaginate from 'react-paginate';
import DashboardFrame from '../../../components/DashboardFrame';
import D_Content from '../../../components/DashboardFrame/D_Content';
import CardWithButton from '../../../components/DashboardFrame/D_Content/CardWithButton';
import AddNewPackinglist from './AddNewPackinglist';
import ShowSenderAndReceiver from './ShowSenderAndReceiver';
import { packingsListsCollectionRef } from '../../../services/collectionsRef';

const Packinglist = () => {
    const { user, logoutUser } = useUserContext();
    const [ packinglistsAddForm, setPackinglistsAddForm ] = useState(false);
    const [ packinglists, setPackinglists] = useState([]);

    useEffect(() => {
        const fetchPackingLists = query(packingsListsCollectionRef, orderBy('timestamp', 'desc'));
        const showPackinglists = onSnapshot(fetchPackingLists, (snapshot) => {
            setPackinglists(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id})));
        });
        return showPackinglists;
    }, []);

    //pagination start
    const [listsPaginate, setListsPaginate] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const listsPerPage = 10;
    const pagesVisited = pageNumber * listsPerPage;
    const pageCount = Math.ceil(listsPaginate.length / listsPerPage);
    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };

    useEffect(() => {
        setListsPaginate(packinglists.slice(0, 100000));
    },[packinglists]);
    //pagination end

    const toggle = () => {
        setPackinglistsAddForm(!packinglistsAddForm);
    };

    const deletePackinglistParts = async (id) => {
        const packinglistPartsDoc = doc(FireDB, `packinglists/${id}/parts`);
        await deleteDoc(packinglistPartsDoc);
    };

    const deletePackinglist = async (id) => {
        const packinglistDoc = doc(FireDB, 'packinglists', id);
        deletePackinglistParts(id);
        await deleteDoc(packinglistDoc);
    };

    return (
        <DashboardFrame
            user={user}
            logoutUser={logoutUser}
        >
            <D_Content title='Packinglistai'>
                <CardWithButton cardTitle='Packinglistai' buttonTitle={packinglistsAddForm ?  <FaIcons.FaRegMinusSquare onClick={toggle} /> : <FaIcons.FaRegPlusSquare onClick={toggle} />}>
                    {
                        packinglistsAddForm && <AddNewPackinglist />
                    }
                    {
                        packinglists.length > 0 ? packinglists.slice(pagesVisited, pagesVisited + listsPerPage).map((paskinglist) => {
                            return(
                                <div key={paskinglist.id} className='packinglist-info-container'>
                                    <div className='packinglist-info'>
                                        <div className='packinglist-info-title'>
                                            <span>{paskinglist.title}</span>
                                        </div>
                                        <h5 className='packinglist-info-h5'>
                                            <span className='packinglist-info-h5-span'>Siunčiama į  miestą : <em>{paskinglist.sendTo}</em></span>
                                            <span>SEK suma: {paskinglist.checkAmount} / kursas: {paskinglist.rateOfExchange}</span>
                                            <span>Čekio nr.: {paskinglist.checkNumber}</span>
                                        </h5>
                                        <ShowSenderAndReceiver 
                                            senderID={paskinglist.senderID} 
                                            receiverID={paskinglist.receiverID}
                                        />
                                        <h5 className='packinglist-info-h5 mobile'> 
                                            <Link to={`/dashboard/packinglists/print_saved_car_parts/${paskinglist.id}`}  className='packinglist-info-eye-button'>
                                                <FaIcons.FaEye />
                                            </Link>
                                            
                                            <Link to={`/dashboard/packinglists/add_parts/${paskinglist.id}`} className='packinglist-info-eye-button'>
                                                <FaIcons.FaListOl />
                                            </Link>
                                        </h5>
                                        <h5 className='packinglist-info-h5'>
                                            <span>Packinglisto ID: {paskinglist.id}</span>
                                            <span>Užpildė informaciją : {paskinglist.userName}</span> 
                                            {
                                                paskinglist.updatedDate && <span>Atnaujintas : {paskinglist.updatedDate}</span>
                                            }
                                            <span>Pridėtas : {paskinglist.date}</span>
                                          
                                        </h5>
                                    </div>
                                    <div className='packinglist-info-buttons-container'>
                                        <Link to={`/dashboard/packinglists/update_packinglist/${paskinglist.id}`} className='packinglist-info-update-button'>
                                            <FaIcons.FaEdit />
                                        </Link>
                                        <div className='packinglist-info-buttons-cirkle'>
                                            <button type='button' onClick={() => deletePackinglist(paskinglist.id)}  className='packinglist-info-delete-button' ><FaIcons.FaTimes /></button>
                                        </div>
                                    </div>
                                </div>
                            );
                        }) : <div className='packinglist-info-container'>Nėra duomenų.</div>
                    }
                    {
                        listsPaginate.length > 10 &&
                            <div className='listPaginationRow'>
                                <ReactPaginate 
                                    previousLabel={'Ankstesnis'}
                                    nextLabel={'Sekantis'}
                                    pageCount={pageCount}
                                    onPageChange={changePage}
                                    containerClassName={'paginationBttns'}
                                    previousLinkClassName={'previousBttn'}
                                    nextLinkClassName={'nextBttn'}
                                    disabledClassName={'paginationDisabled'}
                                    activeClassName={'paginationActive'}
                                />
                            </div>
                    }
                </CardWithButton>
            </D_Content>
        </DashboardFrame>
    );
};

export default Packinglist;
