import React, { useState } from 'react';
import D_Header from './D_Header';
import D_Sidebar from './D_Sidebar';
import D_active_Sidebar from './D_active_Sidebar';
import { DashboardSidebarMenu } from '../../data/DashboardSidebarMenu';
import D_Footer from './D_Footer';

const DashboardFrame = ({ children, user, logoutUser }) => {
    const [sidebar, setSidebar] = useState(false);

    const showSidebar = () => {
        setSidebar(!sidebar);
    };

    return (
        <div className='dashboard-frame-container'>
            <D_Header user={user} logoutUser={logoutUser} showSidebar={showSidebar} />
            {
                sidebar ? 
                    <D_active_Sidebar
                        DashboardSidebarMenu={DashboardSidebarMenu} 
                        user={user}
                        logoutUser={logoutUser}
                    />
                    :
                    <D_Sidebar
                        DashboardSidebarMenu={DashboardSidebarMenu} 
                    />
            } 
            <main className={sidebar ? 'main-content active' : 'main-content' }>
                <div>{children}</div>
                <D_Footer />
            </main>
           
        </div>
    );
};

export default DashboardFrame;


