import React from 'react';
import DashboardFrame from '../../../components/DashboardFrame';
import D_Content from '../../../components/DashboardFrame/D_Content';
import { useUserContext } from '../../../context/UserAuthContext';
import SingleCard from '../../../components/DashboardFrame/D_Content/SingleCard';
import Companys from './Companys';
import CarBrands from './CarBrands';
import CarParts from './CarParts';

const Settings = () => {
    const { user, logoutUser } = useUserContext();

    return (
        <DashboardFrame
            user={user}
            logoutUser={logoutUser}
        >
            <D_Content title='Nustatymai'>
                <SingleCard title='Automobilių detalės'>
                    <CarParts />
                </SingleCard>  
                <SingleCard title='Automobilių markės ir modeliai'>
                    <CarBrands />
                </SingleCard>  
                <SingleCard title='Packinglistų Įmonių sąrašas'>
                    <Companys />
                </SingleCard>  
            </D_Content>
        </DashboardFrame>
    );
};

export default Settings;