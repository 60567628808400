import React from 'react';
import DashboardFrame from '../../../components/DashboardFrame';
import D_Content from '../../../components/DashboardFrame/D_Content';
import DashboardCards from '../../../components/DashboardFrame/D_Content/DashboardCards';
import { useUserContext } from '../../../context/UserAuthContext';

const Dashboard = () => {
    const { user, logoutUser } = useUserContext();
    return (
        <DashboardFrame
            user={user}
            logoutUser={logoutUser}
        >
            <D_Content title='...'>
                <DashboardCards />
                Dashboard. Turinys.
            </D_Content>
        </DashboardFrame>
    );
};

export default Dashboard;