import React from 'react';

const SingleCard = ({ children, title}) => {
    return (
        <div className='single-card'>
            <div className='single-card-title'>{title}</div>
            <div className='single-card-content'>
                {children}
            </div>
        </div>
    );
};

export default SingleCard;