import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as BsIcons from 'react-icons/bs';

export const DashboardSidebarMenu = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    icon: <FaIcons.FaIgloo />,
    cName: 'sidebar-links active'
  },
  {
    title: 'Automobiliai',
    path: '/dashboard/cars',
    icon: <FaIcons.FaCar />,
    cName: 'sidebar-links'
  },
  {
    title: 'Packinglistai',
    path: '/dashboard/packinglists',
    icon: <BsIcons.BsListCheck />,
    cName: 'sidebar-links'
  },
  {
    title: 'Nustatymai',
    path: '/dashboard/settings',
    icon: <FaIcons.FaCog />,
    cName: 'sidebar-links'
  },
 
//   {
//     title: 'Klientai',
//     path: '/autorized/dashboard',
//     icon: <FaIcons.FaUsers />,
//     cName: 'sidebar-links'
//   },

//   {
//     title: 'Garažas',
//     path: '/autorized/garage',
//     icon: <FaIcons.FaWarehouse />,
//     cName: 'sidebar-links'
//   },
//   {
//       title: 'Detalės',
//       path: '/autorized/dashboard',
//       icon: <FaIcons.FaReceipt />,
//       cName: 'sidebar-links'
//   },
//   {
//     title: 'Sandėlys',
//     path: '/autorized/warehouse',
//     icon: <FaIcons.FaRegListAlt />,
//     cName: 'sidebar-links'
//   },

//   {
//     title: 'Nustatymai',
//     path: '/autorized/settings',
//     icon: <FaIcons.FaCog />,
//     cName: 'sidebar-links'
//   },
   // {
  //   title: 'Grupės',
  //   path: '/autorized/team_group',
  //   icon: <IoIcons.IoMdPeople />,
  //   cName: 'sidebar-links'
  // },
  // ,
  // {
  
  // {
  //   title: 'Charts',
  //   path: '/autorized/charts',
  //   icon: <FaIcons.FaRegChartBar />,
  //   cName: 'sidebar-links'
  // }
];