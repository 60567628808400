import { collection, deleteDoc, doc, onSnapshot, orderBy, query, updateDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FireDB } from '../../../../server/firebase-config';
import * as FaIcons from 'react-icons/fa';
import UpdateItem from './UpdateIList';

const PartsList = () => {
    const { id } = useParams();
    const [parts, setParts] = useState([]);
    const [newPartName, setNewPartName] = useState('');
    const [newCode, setNewCode] = useState('');
    const [newUnits, setNewUnits] = useState('');
    const [newWeight, setNewWeight] = useState('');
    const [clicked, setClicked] = useState(false);
    const [totalUnits, setTotalUnits] = useState('');
    const [totalWeight, setTotalWeight] = useState('');

    const collectionRef = collection(FireDB, `packinglists/${id}/parts`);

    useEffect(() => {
        const fethRowsData = query(collectionRef, orderBy('timestamp', 'asc'));
        const onsub = onSnapshot(fethRowsData, (snapshot) => {
            setParts(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data()})));
        });
        return onsub;
    }, []);

    useEffect(() => {
        let unitsArray = [];
        let totalCountedUnits = 0;
        let totalWeight = 0;
        const countUnits = async () => {
            parts.map((count) => {
                const arrayNumber = {newUnits: count.units, newWeight: count.partWeight};
                unitsArray.push(arrayNumber);
            });
        };
        countUnits();
       
        for (let x in unitsArray) {
            totalCountedUnits += Number(unitsArray[x].newUnits);
            totalWeight += Number(unitsArray[x].newUnits * unitsArray[x].newWeight);
        }
        setTotalUnits(totalCountedUnits);
        setTotalWeight(totalWeight);
    },[parts]);
   
    const handleDeletePart = async (partId) => {
        const partDocument = doc(collectionRef, partId);
        await deleteDoc(partDocument);
    };

    const handleUpdatePartData = async (partId) => {
        const partDoc = doc(collectionRef, partId);
        await updateDoc(partDoc, {partName: newPartName, code: newCode, units: newUnits, partWeight: newWeight}); 
        setNewPartName('');
        setNewCode('');
        setNewUnits('');
        setNewWeight('');
        setClicked(!clicked);
    };
 
    const toggle = (partId) => {
        if(clicked === partId) {
            return setClicked(null);
        }
        setClicked(partId);
    };

    return (
        <div className='partsList'>
            <ul className='partsList-pc-screen'>
                {
                    parts.length > 0 ? 
                    <>
                        <li className='partsList-info'>
                            <span>Bendrai vnt : {totalUnits}</span>
                            <span>Bendras svoris : {totalWeight} kg</span>
                        </li>
                        <li  className='partsList-container top'>
                            <span className='number '>Nr.</span>
                            <span className='partName'>Pavadinimas</span>
                            <span className='units'>vnt</span>
                            <span className='partWeight'>kg</span>
                            <span className='action'>&nbsp;</span>
                        </li>
                    </>
                    : 
                    <p>Tuščias sąrašas. Suveskite informaciją į laukelius.</p>
                }

                {
                    parts && parts.map((part, index) => (
                        <>
                        <li className='partsList-container' key={part.id}>
                            <span className='number'>{index + 1}.</span>
                            <span className='partName'  onClick={() => toggle(part.id)}>{part.code} {part.partName}</span>
                            <span className='units'>{part.units}</span>
                            <span className='partWeight'>{part.partWeight}</span>
                            <div className='action buttons'>
                                {
                                    clicked === part.id ? <FaIcons.FaRegEdit /> : <button type='button' onClick={() => handleDeletePart(part.id)}><FaIcons.FaTimes /></button>
                                }
                            </div>
                        </li>
                        
                            {
                                clicked === part.id &&
                                    <UpdateItem 
                                        part={part}
                                        newPartName={newPartName}
                                        setNewPartName={setNewPartName}
                                        newCode={newCode}
                                        setNewCode={setNewCode}
                                        newUnits={newUnits}
                                        setNewUnits={setNewUnits}
                                        newWeight={newWeight}
                                        setNewWeight={setNewWeight}
                                        handleUpdatePartData={handleUpdatePartData}
                                    />
                            }
                        </>
                    ))
                }
            </ul>

            <ul className='partsList-mobile-screen'>
                {
                    parts.length > 0 ? 
                    <>
                        <li className='partsList-info'>
                            <span>Bendrai vnt : {totalUnits}</span>
                            <span>Bendras svoris : {totalWeight} kg</span>
                        </li>
                    </>
                    : 
                    <p>Tuščias sąrašas. Suveskite informaciją į laukelius.</p>
                }
                {
                    parts && parts.map((part, index) => (
                        <>
                        <li className='partsList-container' key={part.id}>
                            <div>
                                <span className='number'>{index + 1}.</span>
                                <span className='partName'  onClick={() => toggle(part.id)}>{part.code} {part.partName}</span>
                            </div>
                            <div>
                                <span className='units'>vnt = {part.units}</span>
                                <span className='partWeight'>vnt/kg = {part.partWeight}</span>
                                <div className='action buttons'>
                                    {
                                        clicked === part.id ? <FaIcons.FaRegEdit /> : <button type='button' onClick={() => handleDeletePart(part.id)}><FaIcons.FaTimes /></button>
                                    }
                                </div>
                            </div>
                        </li>
                        
                            {
                                clicked === part.id &&
                                    <UpdateItem 
                                        part={part}
                                        newPartName={newPartName}
                                        setNewPartName={setNewPartName}
                                        newCode={newCode}
                                        setNewCode={setNewCode}
                                        newUnits={newUnits}
                                        setNewUnits={setNewUnits}
                                        newWeight={newWeight}
                                        setNewWeight={setNewWeight}
                                        handleUpdatePartData={handleUpdatePartData}
                                    />
                            }
                        </>
                    ))
                }
            </ul>
        </div>
    );
};

export default PartsList;