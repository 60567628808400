import { collection, onSnapshot, query } from "firebase/firestore";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import DashboardFrame from "../../../../components/DashboardFrame";
import D_Content from "../../../../components/DashboardFrame/D_Content";
import SingleCard from "../../../../components/DashboardFrame/D_Content/SingleCard";
import { useUserContext } from "../../../../context/UserAuthContext";
import { FireDB } from "../../../../server/firebase-config";

const PartsPackinglist = () => {
  const { user, logoutUser } = useUserContext();
  const { id } = useParams();

  const [plParts, setPLParts] = useState([]);

  useEffect(() => {
    const collRef = collection(FireDB, `cars/${id}/order`);
    const fetchData = query(collRef);
    const onsub = onSnapshot(fetchData, (snapshot) => {
      setPLParts(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    });

    return onsub;
  }, []);

  return (
    <DashboardFrame user={user} logoutUser={logoutUser}>
      <D_Content title='Automobiliai'>
        <SingleCard title='Packinglist'>
        <h6>Auto id: {id}</h6>
            <Link to={`/dashboard/cars/car_ordered_parts/${id}`}>atgal</Link>
          <ul>
            {plParts.length > 0 &&
              plParts.map((part) => <CheckedRow key={part.id} part={part} />)}
          </ul>
        </SingleCard>
      </D_Content>
    </DashboardFrame>
  );
};

export default PartsPackinglist;

const CheckedRow = ({ part }) => {
  const [showProtokol, setShowProtocol] = useState(false);
  const checkValue = (value) => {
    let filteredValue;
    if (value === "gera" || value === "smulkus defektai") {
      filteredValue = true;
    } else {
      filteredValue = false;
    }
    return filteredValue;
  };

  return (
    <>
      {checkValue(part.status) && (
        <li>
          {part.name}{" "}
          <button
            type='button'
            onClick={() =>
              setShowProtocol(!showProtokol)
            }
          >
            Protokolas
          </button>
          {
            showProtokol &&
            <div style={{ margin: '5px', border: '1px solid gray' }}>
              <p>Protokolas. Detale patikrino:  {part.userUpdated}</p>
              <p>Detalė : {part.name}</p>
              <div style={{ color: 'red'}}>
                <p>How and what has been tested? </p>
                <p>Occular inspection, check that nothing is brent and crashed.</p>
                <p>Occular inspection, check that brackets is OK.</p>
                </div>
            </div>
          }
        </li>
      )}
    </>
  );
};
