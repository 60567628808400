import React, { useState } from 'react';
import { useUserContext } from '../../../context/UserAuthContext';
import { Link } from 'react-router-dom';
import logoImg from '../../../images/logo.png';

const Register = () => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [password, setPassword] = useState('');
    const [emptyFields, setEmptyFields] = useState('');
    const { registerUser } = useUserContext();

    const handleRegister = async (event) => {
        event.preventDefault();
        setError('');
        if(email === '' || password === ''){
            setEmptyFields('Tušti laukeliai !');
        }
        try {
            await registerUser(email, password);
        } catch (err) {
            setError(err.message);
        }
        setEmail('');
        setPassword('');

    };
    
    return (
        <div className='login-container'>
            <div className='login-form'>
                <form onSubmit={handleRegister}>
                    <img src={logoImg} alt='logo' />
                    {error && <p>{`${email} - ${error}`}</p>}
                    {emptyFields === '' ? false : <p>Klaida. Tušti laukeliai !</p>}
                    <input placeholder='El.paštas' type='email' onChange={(event) => setEmail(event.target.value)} required autoComplete='true'/>
                    <input placeholder='Slaptažodis' type='password' onChange={(event) => setPassword(event.target.value)} required autoComplete='true'/>
                    <button type='submit'>Registruotis</button>
                </form>
                <ul>
                    <li><Link to='/'>Pagrindinis</Link></li>
                    <li><Link to='/login'>Prisijungti</Link></li>
                </ul>  
            </div>
        </div>
    );
};

export default Register;