import { doc, onSnapshot } from 'firebase/firestore';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { companysCollectionRef } from '../../../../services/collectionsRef';

const ShowSenderAndReceiver = ({ senderID, receiverID }) => {
    const [sender, setSender] = useState([{}]);
    const [receiver, setReseiver] = useState([{}]);

    useEffect(() => {
        if(senderID){
            const showData = onSnapshot(doc(companysCollectionRef, senderID), (doc) => {
                setSender(doc.data());
            });
            return showData;
        }
    }, [senderID]);

    useEffect(() => {
        if(receiverID){
            const showData = onSnapshot(doc(companysCollectionRef, receiverID), (doc) => {
                setReseiver(doc.data());
            });
            return showData;
        }
    }, [receiverID]);

    return (
        <>
            <h5 className='packinglist-info-h5'>
                <span className='packinglist-info-h5-span'>Siuntėjas: {sender ? sender.companyName : 'nėra duomenų'}</span>
            </h5>
            <h5 className='packinglist-info-h5'>
                <span className='packinglist-info-h5-span'>Gavėjas: {receiver ?  receiver.companyName : 'nėra duomenų'}</span>
            </h5>
        </>
    );
};

export default ShowSenderAndReceiver;