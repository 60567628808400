export const calculatedCheckAmountInEuro = (sek, kurs) => {
    let result = sek / kurs;
    return result.toFixed(2);
};

export const countedPartsPriceInSek = (checkAmountSek, length) => {
    let resultCountedPartsPriceInSek = checkAmountSek / length;
    return resultCountedPartsPriceInSek.toFixed(2);
};

export const countedOnePartPriceInSek = (checkAmountSek, length, partsUnits) => {
    let resultCountedOnePartPriceInSek =( checkAmountSek / length) / partsUnits;
    return resultCountedOnePartPriceInSek.toFixed(2);
};


export const countedPartsPriceInEuro = (checkAmountEuro , length) => {
    let resultCountedPartsPriceInEuro = checkAmountEuro / length;
    return resultCountedPartsPriceInEuro.toFixed(2);
};

export const countedOnePartPriceInEuro = (checkAmountSek, length, partsUnits) => {
    let resultCountedOnePartPriceInSek =( checkAmountSek / length) / partsUnits;
    return resultCountedOnePartPriceInSek.toFixed(2);
};
