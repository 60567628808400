import React from 'react';

const D_Footer = () => {
    const date = new Date();

    return (
        <footer className='dashboard-footer'>
           grumin &copy; {date.getFullYear()}
        </footer>
    );
};

export default D_Footer;