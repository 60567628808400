import React from "react";
import { useUserContext } from "../../../../context/UserAuthContext";
import DashboardFrame from "../../../../components/DashboardFrame";
import D_Content from "../../../../components/DashboardFrame/D_Content";
import SingleCard from "../../../../components/DashboardFrame/D_Content/SingleCard";
import { Link, useParams } from "react-router-dom";
import { useEffect } from "react";
import { collection, onSnapshot, query } from "firebase/firestore";
import { FireDB } from "../../../../server/firebase-config";
import { useState } from "react";
import UpdateStatus from "./UpdateStatus";
import CustumerForm from "./CustumerForm";

// import { FireDB } from '../../../../server/firebase-config';
// import { addDoc, collection, doc, getDoc, onSnapshot, orderBy, query, serverTimestamp } from 'firebase/firestore';
// import PartCard from './PartCard';

const OrderedParts = () => {
  const { user, logoutUser } = useUserContext();
  const { id } = useParams();

  const [orderedParts, setOrderedParts] = useState([]);
 

  useEffect(() => {
    const partsCollectionRef = collection(FireDB, `cars/${id}/order`);
    const fetchPartsData = query(partsCollectionRef);
    const onsub = onSnapshot(fetchPartsData, (snapshot) => {
      setOrderedParts(
        snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      );
    });
    onsub;
  }, []);

  // console.log('orderedParts :', orderedParts);

  return (
    <DashboardFrame user={user} logoutUser={logoutUser}>
      <D_Content title='Automobiliai'>
        <SingleCard title='Sarašas'>
          <h6>Auto id: {id}</h6>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>

          <Link to={`/dashboard/cars/car_parts_order/${id}`}>atgal</Link>
          <Link to={`/dashboard/cars/packinglist/${id}`}>Packing list</Link>
          </div>
          <ol>
            {
              orderedParts.length > 0 && 
                orderedParts.map((part) => (
                  <li key={part.id} style={{ margin: '5px',padding: '10px', backgroundColor: 'rgb(225, 224, 224)' , borderRadius: '5px'}}>
                    {part.name}
                    <span style={{ backgroundColor:  part.statusColor }}>status</span>
                    <span> / užsakė: {part.userAdded} / įvertino: {part.userUpdated}</span>
                    <UpdateStatus id={id} part={part}/>
                    <CustumerForm  id={id} part={part}/>
                  </li>
                ))
            }

          </ol>
          <ul style={{ padding: '20px 0', display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <li>
                <h4>Kokybės nustatymo spalvos:</h4>
            </li>
            <li style={{padding: '3px'}}>
                <span style={{ backgroundColor: 'gray', padding: '3px'}}>statusas</span> = nenustatyta
            </li>
            <li style={{padding: '3px'}}>
                <span style={{ backgroundColor: 'green', padding: '3px'}}>statusas</span> = gera
            </li>
            <li style={{padding: '3px'}}>
                <span style={{ backgroundColor: 'yellow',padding: '3px'}}>statusas</span> = yra smulkiu defektu, bet veikia.
            </li>
            <li style={{padding: '3px'}}>
                <span style={{ backgroundColor: 'red', padding: '3px'}}>statusas</span> = dideli defektai
            </li>
          </ul>
        </SingleCard>
      </D_Content>
    </DashboardFrame>
  );
};

export default OrderedParts;
