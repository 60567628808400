import React, { useEffect, useState } from 'react';
import { useUserContext } from '../../../context/UserAuthContext';
import { carsCollectionRef } from '../../../services/collectionsRef';
import { onSnapshot, orderBy, query } from 'firebase/firestore';
// import * as FaIcons from 'react-icons/fa';
import DashboardFrame from '../../../components/DashboardFrame';
import D_Content from '../../../components/DashboardFrame/D_Content';
import AddNewCar from './AddNewCar';
import CarCard from './CarCard';
// import CardWithButton from '../../../components/DashboardFrame/D_Content/CardWithButton';
// import CarContainer from './CarContainer';
// import AddNewCar from './AddNewCar';

const Cars = () => {
    const { user, logoutUser } = useUserContext();
    // const [ carsAddForm, setCarsAddForm ] = useState(false);
    const [ cars, setCars ] = useState([]);

    useEffect(() => {
        const fetchCarsData = query(carsCollectionRef, orderBy('timestamp', 'desc'));
        const onsub = onSnapshot(fetchCarsData, (snapshot) => {
            setCars(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        });
        onsub;
    }, []);

    // const toggle = () => {
    //     setCarsAddForm(!carsAddForm);
    // };



    return (
        <DashboardFrame
            user={user}
            logoutUser={logoutUser}
        >
            <D_Content title='Automobiliai'>
               <AddNewCar />
                
                {/* <CardWithButton 
                    cardTitle='Automobiliai' 
                    buttonTitle={carsAddForm ?  <FaIcons.FaRegMinusSquare onClick={toggle} /> : <FaIcons.FaRegPlusSquare onClick={toggle} />}
                >
              
              
                </CardWithButton> */}

               


                {
                    cars.length > 0 ? 
                    cars.map((car) => (
                        <CarCard 
                            key={car.id} 
                            car={car} 
                            carsCollectionRef={carsCollectionRef} 
                        />
                    )) 
                    : 'No data.'
                }

                 {/* {
                    cars.length > 0 ? 
                        <CarContainer cars={cars}/>
                        : 'No data.'
                } */}
            </D_Content>
        </DashboardFrame>
    );
};

export default Cars;