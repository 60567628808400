import React, { useEffect, useState } from 'react';
import { addDoc, collection } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { FireDB, FireStorageDB } from '../../../../server/firebase-config';

const AddCarImages = ({ carId }) => {
    const [imgName, setImageName] = useState('');
    const [showButton, setShowbutton] = useState(false);

    useEffect(() => {
        if(imgName !== ''){
            setShowbutton(true);
        } else {
            setShowbutton(false);
        }
    }, [imgName]);

    const handleImageChange = (event) => {
       
        if (event.target.files[0]){
            const uploadImage = () => {
                const imageRef = ref(FireStorageDB, `cars/${Date.now()}-${event.target.files[0].name}`);
                uploadBytes(imageRef, event.target.files[0]).then(() => {
                getDownloadURL(imageRef).then((url) => {
                    const images = [];
                    if (url) {
                        images.push(url);
                    }
                    setImageName(images);
                }).catch((error) => {
                    alert(error.message, 'error  getting the image url');
                });
                }).catch((error) => {
                alert(error.message);
                });
            };
            uploadImage();
        }

        setTimeout(() => {
            event.target.value = null;
        },2000);

    };

    const AddImagesToCollection = async (carId) => {
        const newColRef = collection(FireDB, `cars/${carId}/images`);
        const carImagesUrl = {imgName};
        await addDoc(newColRef, carImagesUrl);
        setImageName('');
    };

    return (
        <div className='uploadSingelImg-container'>
            <div className='top-box'>
                <img src={imgName || 'http://grumin.lt/web-images/placeholder.jpg'} width='200px' alt=''/>
                
                {
                    showButton ? <button type='button' className='custom-button' onClick={() => AddImagesToCollection(carId)}>Pridėkite į galeriją</button> : <input type='file' onChange={handleImageChange} />
                }
            </div>
        </div>
    );
};

export default AddCarImages;