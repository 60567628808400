import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import * as FaIcons from 'react-icons/fa';
import { FireDB } from '../../../../server/firebase-config';
import { collection, doc, onSnapshot, orderBy, query } from 'firebase/firestore';
import { calculatedCheckAmountInEuro, countedOnePartPriceInEuro, countedOnePartPriceInSek, countedPartsPriceInEuro, countedPartsPriceInSek } from '../src';
import PrintListSenderReceiver from '../ShowSenderAndReceiver/PrintListSenderReceiver';
import { packingsListsCollectionRef } from '../../../../services/collectionsRef';


const PrintSavedParts = () => {
    const { id } = useParams();
    const [state, setState] = useState({});
    const [savedParts, setSavedParts] = useState([]);
    const [totalUnits, setTotalUnits] = useState('');
    const [totalWeight, setTotalWeight] = useState('');

    useEffect(() => {
        const unsub = onSnapshot(doc(packingsListsCollectionRef, id), (doc) => {
            setState(doc.data());
        });
        return unsub;
    },[]);

    const partsCollectionRef = collection(FireDB, `packinglists/${id}/parts`);

    useEffect(() => {
        const fethPartsData = query(partsCollectionRef, orderBy('timestamp', 'asc'));
        const onsub = onSnapshot(fethPartsData, (snapshot) => {
            setSavedParts(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data()})));
        });
        return onsub;
    },[state]);

    useEffect(() => {
        let unitsArray = [];
        let totalCountedUnits = 0;
        let totalWeight = 0;
        const countUnits = async () => {
            savedParts.map((count) => {
                const arrayNumber = {newUnits: count.units, newWeight: count.partWeight};
                unitsArray.push(arrayNumber);
            });
        };
        countUnits();
       
        for (let x in unitsArray) {
            totalCountedUnits += Number(unitsArray[x].newUnits);
            totalWeight += Number(unitsArray[x].newUnits * unitsArray[x].newWeight);
        }
        setTotalUnits(totalCountedUnits);
        setTotalWeight(totalWeight);
    },[savedParts]);

    return (
        <div className='print-pl-container'>
            <div className='print-pl-container-div'>
                <Link to={`/dashboard/packinglists/add_parts/${id}`}>
                    <FaIcons.FaChevronLeft className='print-pl-icon' />
                </Link>
            </div>
            <div className='print-pl-info-box'>
                <h2>{state.title}</h2>
                <h4>Čekio nr.: <span>{state.checkNumber}</span></h4>
                <h4>Packinglist id: <span>{id}</span></h4>
                <PrintListSenderReceiver 
                    senderID={state.senderID}
                    receiverID={state.receiverID}
                />
            </div>
            <table className='print-pl-table'>
                <thead>
                    <tr>
                        <th>Nr.</th>
                        <th className='table-partName'>Pavadinimas</th>
                        <th>vnt {totalUnits}</th>
                        <th>Čekio suma sek<br />{state.checkAmount}</th>
                        <th>Detalės kaina sek</th>
                        <th>Čekio suma eur <br />{calculatedCheckAmountInEuro(state.checkAmount, state.rateOfExchange)}</th>
                        <th>Detalės kaina eur</th>
                        <th>Svoris {totalWeight} kg</th>
                        <th>Detalės kg</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        savedParts && savedParts.map((part, index) => {
                            return(
                                <tr key={part.id}>
                                    <td className='print-pl-table-col1'>{index + 1}.</td>
                                    <td className='print-pl-table-col2'>{part.code} {part.partName} {part.comments}</td>
                                    <td className='print-pl-table-col'>{part.units}</td>
                                    <td className='print-pl-table-col'><span>{countedPartsPriceInSek(state.checkAmount, savedParts.length)}</span></td>
                                    <td className='print-pl-table-col'><span>{countedOnePartPriceInSek(state.checkAmount, savedParts.length, part.units )}</span></td>
                                    <td className='print-pl-table-col'><span>{countedPartsPriceInEuro(calculatedCheckAmountInEuro(state.checkAmount, state.rateOfExchange), savedParts.length,  part.units)}</span></td>
                                    <td className='print-pl-table-col'><span>{countedOnePartPriceInEuro(calculatedCheckAmountInEuro(state.checkAmount,  state.rateOfExchange), savedParts.length, part.units )}</span></td>
                                    <td className='print-pl-table-col'>{part.units * part.partWeight}</td>
                                    <td className='print-pl-table-col'>{part.partWeight}</td>
                                </tr>
                            );
                        })
                    }
                </tbody>
            </table>
            <p className='warningText'>Lentelė atvaizduojama tik , kai ekrano plotis didesnis , nei 1000px !</p>
            <h5>Paruošė: {state.userName}</h5>
        </div>  
    );
};

export default PrintSavedParts;