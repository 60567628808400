import React from 'react';
import { Link } from 'react-router-dom';
import './_d_sidebar.scss';

const D_Sidebar = ({ DashboardSidebarMenu }) => {
    return (
        <div className='sidebar-icons-menu'>
            <ul className='sidebar-menu-items'>
                {DashboardSidebarMenu.map((item, index) => {
                    return (
                    <li key={index}>
                        <Link to={item.path} className={item.cName}>
                            <span>{item.icon}</span>
                        </Link>
                    </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default D_Sidebar;