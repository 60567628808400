import React, { useState, useEffect } from 'react';
import { addDoc, collection, deleteDoc, doc, onSnapshot, query } from 'firebase/firestore';
import { FireDB } from '../../../../server/firebase-config';
import PartsList from './PartsList';


// import BrandsList from './BrandsList';

const CarParts = () => {
    const [carPart, setCarPart] = useState('');
    const [carParts, setCarParts] = useState([]);
    const [emptyFieldsErr, setEmptyFieldsErr] = useState('');

    useEffect(() => {
        const partsCollectionRef =  collection(FireDB, 'partNames');
        const fetchCompanysData = query(partsCollectionRef);
        const onsub = onSnapshot(fetchCompanysData, (snapshot) => {
            setCarParts(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id, })));
        });
        onsub;
    }, []);

    const checkPartName = (value) => {
        let lowerCaseWord = value.toLowerCase();
        let partDoc = [];
        partDoc = carParts.filter(doc => doc.name === lowerCaseWord);
        if(partDoc.length > 0){
            return true;
        }
    };

   
    const handleAddCarBrand = async (event) => {
        event.preventDefault();
        if(carPart === '' || carPart === '-' || carPart === '.' || carPart === '*'  || carPart === '/'){
            setEmptyFieldsErr('Privaloma suvesti markės pavadinimą.');
            setTimeout(() => {
                setEmptyFieldsErr('');
            }, 2000);
        }
        else {
            if(checkPartName(carPart) === true) {
                setEmptyFieldsErr('Jau yra tokia markė.');
                setTimeout(() => {
                    setEmptyFieldsErr('');
                }, 2000);
            } else{
                const partsCollectionRef =  collection(FireDB, 'partNames');
                const newPart = {name: carPart.toLowerCase(), status: false};
                await addDoc(partsCollectionRef, newPart);
            }   
        }
        setCarPart('');  
    };

    const handleDeleteCarPart =  async (id) => {
        const partDoc = doc(FireDB, 'partNames', id );
        await deleteDoc(partDoc);
    };

    const sortCarParts = carParts && carParts.sort( (a, b) => a.name.localeCompare(b.name) );

    return (
        <div className='settings-carBrands'>
            <p style={{ color: '$red' }}>{emptyFieldsErr !== '' &&  emptyFieldsErr}</p>
            <form onSubmit={handleAddCarBrand}>
                <input type='text' name='partName' value={carPart} placeholder='Detalė' onChange={(event) => setCarPart(event.target.value)}  required />
                <button>Pridėti automobilio detale</button>
            </form>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                {
                    carParts.length > 0 && 
                    sortCarParts.map((part) => (
                        <PartsList key={part.id} part={part} handleDeleteCarPart={handleDeleteCarPart} />
                    ))
                }
            </div>
        </div>
    );
};

export default CarParts;