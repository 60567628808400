import { doc, updateDoc } from 'firebase/firestore';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { FireDB } from '../../../../server/firebase-config';

const CustumerForm = ({id, part}) => {
    const [data, setData] = useState({});

  const inputChange = (e) => {
    const name = e.target.name;
    const value =  e.target.value;
    setData({
      ...data, [name] : value
    });
  };

  useEffect(() => {
    setData({...part, customer: part.customer});

  }, [id, part]);
    
  const handleChangeCustumer = async (id, partId, value) => {
    const partDoc = {customer: value };
    const partDocRef = doc(FireDB, `cars/${id}/order/`, partId);
    await updateDoc(partDocRef, partDoc);
    console.log('is updated');
   
  };

  return (
    <div>
        Detale rezervuoja : <span style={{ color: data.customer === 'klaipeda' ? 'blue' : 'purple' }}>{data.customer}</span>
        <form onSubmit={(e) => e.preventDefault()} >
          <select name='customer' value={data.customer} onChange={inputChange}>
            <option value='nenustatyta'>nenustatyta</option>
            <option value='mazeikiai'>mazeikiai</option>
            <option value='klaipeda'>klaipeda</option>
            <option value='kaunas'>kaunas</option>
            <option value='ukraina'>ukraina</option>
          </select>
          <button type='button' onClick={() => handleChangeCustumer(id, part.id, data.customer)}>rezervuoti</button>
        </form>
    </div>
  );
};

export default CustumerForm;