import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyCu-MS1PV6QAiWIdW0HhJ9qPS2OQWdvrPs",
  authDomain: "ucarparts-d74b8.firebaseapp.com",
  projectId: "ucarparts-d74b8",
  storageBucket: "ucarparts-d74b8.appspot.com",
  messagingSenderId: "1014756957572",
  appId: "1:1014756957572:web:e6aee4a33edca5893c51d1"
};

const app = initializeApp(firebaseConfig);
const FireAuth = getAuth(app);
const FireDB = getFirestore(app);
const FireStorageDB = getStorage(app);
export { FireAuth, FireDB, FireStorageDB };
