import React, {useState, useEffect} from 'react';
import DashboardFrame from '../../../../components/DashboardFrame';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useUserContext } from '../../../../context/UserAuthContext';
import D_Content from '../../../../components/DashboardFrame/D_Content';
import SingleCard from '../../../../components/DashboardFrame/D_Content/SingleCard';
import { deleteDoc, doc, getDoc, updateDoc } from 'firebase/firestore';
import { FireDB } from '../../../../server/firebase-config';
import ShowQRcode from '../CarContainer/ShowQRCode';
import PartUploadImg from '../CarContainer/PartUploadImg';
import ShowPartGallery from '../CarContainer/ShowPartGallery';

const carInitialState = {
    brand: '',
    model: ''
};
const partInitialState = {
    partTitle: '',
};


const PartInfo = () => {
    const { user, logoutUser } = useUserContext();
    const { id, partid } = useParams();
    const [car, setCar] = useState(carInitialState);
    const [part, setPart] = useState(partInitialState);

    const navigate = useNavigate();

    useEffect(() => {
        const carCollectionRef = doc(FireDB, 'cars', id);
        const fetchData = async () => {
            try{
                const response = await getDoc(carCollectionRef);
                if(response.exists()) {
                    setCar(response.data());
                } else {
                    console.log('Document does not exist.');
                }
            }catch(err){
                console.log(err);
            }
        };
        fetchData();
    }, []);

    const partCollectionRef = doc(FireDB, `cars/${id}/parts`, partid);

    useEffect(() => {
        const fetchData = async () => {
            try{
                const response = await getDoc(partCollectionRef);
                if(response.exists()) {
                    setPart(response.data());
                } else {
                    console.log('Document does not exist.');
                }
            }catch(err){
                console.log(err);
            }
        };
        fetchData();
    }, []);

    const handleUpdate = async (event) => {
        event.preventDefault();

        const partInfo = {partTitle: part.partTitle};

        await updateDoc(partCollectionRef, partInfo);
        navigate(`/dashboard/cars/car_parts_collection/${id}`);
    };

    const handleDeletePart = async () => {
        await deleteDoc(partCollectionRef);
        navigate(`/dashboard/cars/car_parts_collection/${id}`);
    };

    return (
        <DashboardFrame
        user={user}
        logoutUser={logoutUser}
        >
             <D_Content title='Automobiliai'>
                <SingleCard  title={`${car.brand} ${car.model}`}>
                    <Link to={`/dashboard/cars/car_parts_collection/${id}`} style={{ display: 'block', width: '80px', margin: '10px 0',textDecoration: 'none', padding: '7px 15px', backgroundColor: '#414141', color: 'lightgray' }}>Atgal</Link>
                    <h6 style={{ color: 'lightgray' }}>Auto id: {id}</h6>
                    <h6 style={{ color: 'lightgray' }}>Detalės id: {partid} {id}</h6>
                    <p>Detalės nr.: {part.partCardId}</p>
                    <ShowQRcode car={car} part={part}/>
                    <form onSubmit={handleUpdate}>
                        <input type='text' value={part.partTitle} onChange={(event) => setPart({partTitle: event.target.value})} required style={{width: '300px' }}/>
                        <button type='submit' style={{ display: 'block', width: '120px', margin: '10px 0',textDecoration: 'none', padding: '7px 15px', backgroundColor: 'green', color: 'lightgray' }}>Atnaujinti</button>
                    </form>
                    <br />
                    <br />
                    <br />
                    <button type='button' onClick={() => handleDeletePart(partid)} style={{ display: 'block', width: '140px', margin: '10px 0',textDecoration: 'none', padding: '7px 15px', backgroundColor: 'red', color: 'lightgray' }}>Ištrinti detalę</button>
                    <br />
                    <br />
                    <br />
                    <PartUploadImg id={id} partid={partid}/>
                    <ShowPartGallery id={id} partid={partid}/>
                </SingleCard>
             </D_Content>
        </DashboardFrame>
    );
};

export default PartInfo;