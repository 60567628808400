import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FireDB } from '../../../../server/firebase-config';
import { collection, doc, onSnapshot, updateDoc } from 'firebase/firestore';
import DashboardFrame from '../../../../components/DashboardFrame';
import D_Content from '../../../../components/DashboardFrame/D_Content';
import SingleCard from '../../../../components/DashboardFrame/D_Content/SingleCard';
import { useUserContext } from '../../../../context/UserAuthContext';

const UpdatePackinglist = () => {
    const { user, logoutUser } = useUserContext();
    const { id } = useParams();

    const [updatePackinglist, setUpdatePackinglist] = useState([{}]);

    const [updateTitle, setUpdateTitle] = useState('');
    const [updateSendTo, setUpdateSendTo] = useState('');
    const [updateCheckAmount, setUpdateCheckAmount] = useState('');
    const [updateRateOfExchange, setUpdateRateOfExchange] = useState('');
    const [updateCheckNumber, setUpdateCheckNumber] = useState('');
    const [updateUserName, setUpdateUserName] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        const paleteCollectionRef = collection(FireDB, 'packinglists');
        const showData = onSnapshot(doc(paleteCollectionRef, id), (doc) => {
            setUpdatePackinglist(doc.data());
        });
        return showData;
    },[id]);

    const showNewDate = new Date();
    const showTime = () => {
        if(showNewDate.getHours() < 10 && showNewDate.getMinutes() < 10){
            return `0${showNewDate.getHours()}:0${showNewDate.getMinutes()}`;
        } else if(showNewDate.getHours() < 10 && showNewDate.getMinutes() >= 10){
            return `0${showNewDate.getHours()}:${showNewDate.getMinutes()}`;
        } if(showNewDate.getHours() >= 10 && showNewDate.getMinutes() < 10){
            return `${showNewDate.getHours()}:0${showNewDate.getMinutes()}`;
        } else{
            return showNewDate.getHours()+':'+showNewDate.getMinutes();
        }
    };
    const packinglistDate = showNewDate.getFullYear()+'/'+showNewDate.getMonth()+'/'+showNewDate.getDate()+' '+showTime();

    useEffect(() => {
        if (id) {
            setUpdateTitle(updatePackinglist.title);
            setUpdateSendTo(updatePackinglist.sendTo);
            setUpdateCheckAmount(updatePackinglist.checkAmount);
            setUpdateRateOfExchange(updatePackinglist.rateOfExchange);
            setUpdateCheckNumber(updatePackinglist.checkNumber);
            setUpdateUserName(updatePackinglist.userName);
        } else {
            setUpdateTitle('');
            setUpdateSendTo('');
            setUpdateCheckAmount('');
            setUpdateRateOfExchange('');
            setUpdateCheckNumber('');
            setUpdateUserName('');
        }
    }, [id, updatePackinglist]);

    const packinglistDoc = doc(FireDB, 'packinglists', id);

    const updatePackinglistData = async (updateTitle, updateSendTo, updateCheckAmount, updateRateOfExchange, updateCheckNumber, updateUserName, packinglistDate) => {
        const newPackinglistData = {
            title: updateTitle,
            sendTo: updateSendTo,
            checkAmount: updateCheckAmount,
            rateOfExchange: updateRateOfExchange,
            checkNumber: updateCheckNumber,
            userName: updateUserName,
            updatedDate: packinglistDate
        };
        await updateDoc(packinglistDoc, newPackinglistData);
        navigate('/dashboard/packinglists');
    };

    return (
        <DashboardFrame
            user={user}
            logoutUser={logoutUser}
        >
            <D_Content title='Packinglisto / redagavimas'>
                <SingleCard title='Redaguoti packinglistą'>
                    <Link to='/dashboard/packinglists' className='go-back-link'>
                        atgal
                    </Link>
                    <div className='updatePackinglist-form'>
                        <div className='updatePackinglist-box'>
                            <p>PL id: {id}</p>
                            <div className='updatePackinglist-label-input'>
                                <label>Packinglisto pavadinimas</label>
                                <input 
                                    type='text' 
                                    id='title'
                                    name='title'
                                    value={updateTitle}
                                    onChange={(event) => setUpdateTitle(event.target.value)}
                                />
                            </div>
                            <div className='updatePackinglist-label-input'>
                                <label>Miestas į kur siunčiate</label>
                                <input 
                                    type='text' 
                                    id='sendTo'
                                    name='sendTo'
                                    value={updateSendTo}
                                    onChange={(event) => setUpdateSendTo(event.target.value)}
                                />
                            </div>
                            <div className='updatePackinglist-label-input'>
                                <label>Čekio suma</label>
                                <input 
                                    type='number' 
                                    id='checkAmount'
                                    name='checkAmount'
                                    value={updateCheckAmount}
                                    onChange={(event) => setUpdateCheckAmount(event.target.value)}
                                />
                            </div>
                            <div className='updatePackinglist-label-input'>
                                <label>Eur / Sek kursas</label>
                                <input 
                                    type='number' 
                                    id='rateOfExchange'
                                    name='rateOfExchange'
                                    value={updateRateOfExchange}
                                    onChange={(event) => setUpdateRateOfExchange(event.target.value)}
                                />
                            </div>
                            <div className='updatePackinglist-label-input'>
                                <label>Čekio numeris</label>
                                <input 
                                    type='text' 
                                    id='checkNumber'
                                    name='checkNumber'
                                    value={updateCheckNumber}
                                    onChange={(event) => setUpdateCheckNumber(event.target.value)}
                                />
                            </div>
                            <div className='updatePackinglist-label-input'>
                                <label>Vartotojo vardas</label>
                                <input 
                                    type='text' 
                                    id='userName'
                                    name='userName'
                                    value={updateUserName}
                                    onChange={(event) => setUpdateUserName(event.target.value)}
                                />
                            </div>
                            <button 
                                onClick={() => updatePackinglistData(updateTitle, updateSendTo, updateCheckAmount, updateRateOfExchange, updateCheckNumber, updateUserName, packinglistDate)} 
                            >Atnaujinti</button>
                        </div>
                    </div>
                </SingleCard>
            </D_Content>
        </DashboardFrame>
    );
};

export default UpdatePackinglist;