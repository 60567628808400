export const carBrandInitialState = {
    title: '',
    models: []
};

export const companyInitialState = {
    companyName: '',
    adress: '',
    city: '',
    country: '',
    companyCode: ''
};

export const plInitialState = {
    title: '',
    sendTo: '',
    checkAmount: '',
    rateOfExchange: '',
    checkNumber: '',
    userName: '',
    senderID: '',
    receiverID: ''
};

export const partInitialState = {
    partName: '',
    code: '',
    units: '',
    partWeight: '',
};


export const carInitialState = {
    brand: '',
    model: '',
    year: '', 
    regNr: '',
    chassiNr: '',
    fuelType: '',
    engineCode: '',
    kw: '',
    gearBoxType: '',
    gearBoxCode: '',
    color: '',
    addedInfo: '',
    price: '',
    currency: '',
    weight: '',
    km: '',
    working: '',
    customer: '',
    comments: '',
    status: '',
};