import React from 'react';

const D_Content = ({ title, children }) => {
    return (
        <div className='dashboard-content-container'>
            <h3><span>Dashboard</span> / <span>{title}</span></h3>
            {children}
        </div>
    );
};

export default D_Content;