import { doc, updateDoc } from 'firebase/firestore';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { FireDB } from '../../../../server/firebase-config';

const UpdateStatus = ({ id, part }) => {
  const [data, setData] = useState({});

  const inputChange = (e) => {
    const name = e.target.name;
    const value =  e.target.value;
    setData({
      ...data, [name] : value
    });
  };

  useEffect(() => {
    setData({...part, status: part.status});

  }, [id, part]);

  const handleChangeStatus = async (id, partId, value) => {
    let color;
    if(value === 'gera'){
      color = 'green';
    } else if(value === 'smulkus defektai'){
      color = 'yellow';
    } else if(value === 'defektai'){
      color = 'red';
    } else {
      color = 'gray';
    }

    const partDoc = {status: value, statusColor: color };
    const partDocRef = doc(FireDB, `cars/${id}/order/`, partId);
    await updateDoc(partDocRef, partDoc);
    console.log('is updated');
   
  };

  return (
        <form onSubmit={(e) => e.preventDefault()}>
          <select name='status' value={data.status} onChange={inputChange}>
            <option value={data.status}>{data.status}</option>
            <option value='nenustatyta'>nenustatyta</option>
            <option value='gera'>gera</option>
            <option value='smulkus defektai'>smulkus defectai</option>
            <option value='defektai'>defektai</option>
          </select>
          <button type='button' onClick={() => handleChangeStatus(id, part.id, data.status)}>pakeisti statusa</button>
        </form>
  );
};

export default UpdateStatus;