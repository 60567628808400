import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ROUTES } from '.';
import { UserContextProvider } from '../context/UserAuthContext';
import Home from '../pages/public/Home';
import Login from '../pages/public/Login';
import PageNotFound from '../pages/public/PageNotFound';
import Register from '../pages/public/Register';
import ProtectedRoute from './ProtectedRoute';
import Dashboard from '../pages/authorized/Dashboard';
import Packinglist from '../pages/authorized/Packinglists';
import Settings from '../pages/authorized/Settings/index';
import UpdatePackinglist from '../pages/authorized/Packinglists/UpdatePackinglist';
import AddParts from '../pages/authorized/Packinglists/AddParts';
import PrintSavedParts from '../pages/authorized/Packinglists/PrintSavedParts';
import Cars from '../pages/authorized/Cars';
import UpdateCar from '../pages/authorized/Cars/UpdateCar';
import CarPage from '../pages/authorized/Cars/CarPage';
import CarParts from '../pages/authorized/Cars/CarParts';
import CarPartsCollection from '../pages/authorized/Cars/CarPartsCollection';
import PartInfo from '../pages/authorized/Cars/CarPartsCollection/PartInfo';
import CarPartsOrder from '../pages/authorized/Cars/CarPartsOrder';
import OrderedParts from '../pages/authorized/Cars/CarPartsOrder/OrderedParts';
import PartsPackinglist from '../pages/authorized/Cars/PartsPackinglist';

const Router = () => {
    return (
        <UserContextProvider>
            <Routes path={ROUTES.PUBLIC_DEFAULT}>
                <Route  index element={<Home />} />
                <Route path={ROUTES.PUBLIC_LOGIN} element={<Login />} />
                <Route path={ROUTES.PUBLIC_REGISTER} element={<Register />} />
                <Route path={ROUTES.PAGE_NOT_FOUND} element={<PageNotFound />} />

                <Route path={ROUTES.DASHBOARD}>
                    <Route index element={
                        <ProtectedRoute>
                            <Dashboard />
                        </ProtectedRoute>
                    } />
                    <Route path={ROUTES.DASHBOARD_CARS} element={
                        <ProtectedRoute>
                            <Cars />
                        </ProtectedRoute>
                    } />
                    <Route path={ROUTES.DASHBOARD_CAR_PAGE} element={
                        <ProtectedRoute>
                            <CarPage />
                        </ProtectedRoute>
                    } />
                    <Route path={ROUTES.DASHBOARD_CAR_PARTS} element={
                        <ProtectedRoute>
                            <CarParts />
                        </ProtectedRoute>
                    } />
                    <Route path={ROUTES.DASHBOARD_CAR_PARTS_COLLECTION} element={
                        <ProtectedRoute>
                            <CarPartsCollection />
                        </ProtectedRoute>
                    } />
                    <Route path={ROUTES.DASHBOARD_CAR_PART_INFO} element={
                        <ProtectedRoute>
                            <PartInfo />
                        </ProtectedRoute>
                    } />
                    <Route path={ROUTES.DASHBOARD_CAR_PARTS_ORDER} element={
                        <ProtectedRoute>
                            <CarPartsOrder />
                        </ProtectedRoute>
                    } />
                    <Route path={ROUTES.DASHBOARD_CAR_ORDERED_PARTS} element={
                        <ProtectedRoute>
                            <OrderedParts />
                        </ProtectedRoute>
                    } />
                    <Route path={ROUTES.DASHBOARD_CAR_PACKINGLIST} element={
                        <ProtectedRoute>
                            <PartsPackinglist />
                        </ProtectedRoute>
                    } />
                    <Route path={ROUTES.DASHBOARD_UPDATE_CAR} element={
                        <ProtectedRoute>
                            <UpdateCar />
                        </ProtectedRoute>
                    } />
                    <Route path={ROUTES.DASHBOARD_PACKINGLIST} element={
                        <ProtectedRoute>
                            <Packinglist />
                        </ProtectedRoute>
                    } />
                    <Route path={ROUTES.DASHBOARD_UPDATE_PACKINGLIST} element={
                        <ProtectedRoute>
                            <UpdatePackinglist />
                        </ProtectedRoute>
                    } />
                    <Route path={ROUTES.DASHBOARD_ADD_PARTS_TO_PACKINGLIST} element={
                        <ProtectedRoute>
                            <AddParts />
                        </ProtectedRoute>
                    } />
                    <Route path={ROUTES.DASHBOARD_PACKINGLIST_PRINT_SAVED_PARTS} element={
                        
                            <PrintSavedParts />
                       
                    } />
                    <Route path={ROUTES.DASHBOARD_SETTINGS} element={
                        <ProtectedRoute>
                            <Settings />
                        </ProtectedRoute>
                    } />
                </Route>
            </Routes>
            
        </UserContextProvider>
        
    );
};

export default Router;