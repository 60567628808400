import { addDoc, collection, deleteDoc, doc, onSnapshot, orderBy, query } from 'firebase/firestore';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { FireDB } from '../../../../server/firebase-config';
import { MdDelete } from 'react-icons/md';

const AddModel = ({ brand }) => {
    const [model, setModel] = useState('');
    const [models, setModels] = useState([]);
    const [emptyFieldsErr, setEmptyFieldsErr] = useState('');

    useEffect(() => {
        const carModelsCollectionRef = collection(FireDB, `carBrands/${brand.id}/models`);
        const fetchModelsData = query(carModelsCollectionRef, orderBy('modelName', 'desc'));
        const onsub = onSnapshot(fetchModelsData, (snapshot) => {
            setModels(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id, })));
        });
        onsub;
    }, [brand]);

    const checkModelName = (value) => {
        let lowerCaseWord = value.toLowerCase();
        let modelsCollection = [];
        modelsCollection = models.filter(doc => doc.modelName === lowerCaseWord);
        if(modelsCollection.length > 0){
            return true;
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if(model === '' || model === '-' || model === '.' || model === '*'  || model === '/'){
            setEmptyFieldsErr('Privaloma suvesti markės pavadinimą.');
            setTimeout(() => {
                setEmptyFieldsErr('');
            }, 2000);
        } else {
            if(checkModelName(model) === true) {
                setEmptyFieldsErr('Jau yra toks modelis.');
                setTimeout(() => {
                    setEmptyFieldsErr('');
                }, 2000);
                setModel('');
            } else{
                const carModelsCollectionRef = collection(FireDB, `carBrands/${brand.id}/models`);
                const newModel = {modelName: model.toLowerCase() };
                await addDoc(carModelsCollectionRef, newModel);
                setModel('');
            }
        } 
    };

    const handleDeleteModel =  async (id) => {
        const modelDoc = doc(FireDB, `carBrands/${brand.id}/models`, id );
        await deleteDoc(modelDoc);
    };

    const sortCarModels = models && models.sort( (a, b) => a.modelName.localeCompare(b.modelName) );

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <p style={{ color: '$red' }}>{emptyFieldsErr !== '' &&  emptyFieldsErr}</p>
                <input type='text' value={model} placeholder='Modelio pavadinimas' onChange={(event) => setModel(event.target.value)} />
                <button type='submit'>Pridėti</button>
            </form>

            <div style={{ display: 'flex', flexDirection: 'column', margin: '10px' }}>
                Modeliai:
                {
                    models.length > 0 && 
                    sortCarModels.map((model) => (
                        <span style={{ margin: '5px', textTransform: 'capitalize', display: 'flex', alignItems: 'center' }} key={model.id}>{model.modelName} <button type='button' onClick={() => handleDeleteModel(model.id)}  style={{ border: 'none', backgroundColor: 'white', color: 'red', cursor:'pointer' }}><MdDelete /></button></span>
                    ))
                }

            </div>
          
              
           
        </div>
    );
};

export default AddModel;