// import { addDoc, collection, deleteDoc, doc, onSnapshot, orderBy, query, serverTimestamp} from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
// import { useState } from 'react';
// import { FireDB } from '../../../../server/firebase-config';
// import { useEffect } from 'react';
// import ShowQRcode from '../CarContainer/ShowQRCode';
// import PartUploadImg from '../CarContainer/PartUploadImg';
// import ShowPartGallery from '../CarContainer/ShowPartGallery';
import ShowQRcode from '../CarContainer/ShowQRCode';
import { useParams } from 'react-router-dom';
import { useUserContext } from '../../../../context/UserAuthContext';
import DashboardFrame from '../../../../components/DashboardFrame';
import D_Content from '../../../../components/DashboardFrame/D_Content';
import SingleCard from '../../../../components/DashboardFrame/D_Content/SingleCard';
import { addDoc, collection, deleteDoc, doc, onSnapshot, orderBy, query, serverTimestamp } from 'firebase/firestore';
import { FireDB } from '../../../../server/firebase-config';
import PartUploadImg from '../CarContainer/PartUploadImg';
import ShowPartGallery from '../CarContainer/ShowPartGallery';

const CarParts = () => {
    const { user, logoutUser } = useUserContext();
    const { id } = useParams();

    const [partNumber, setPartNumber] = useState('');
    const [partTitle, setPartTitle] = useState('');
   
    const [parts, setParts] = useState([]);

    const carPartsCollectionRef = collection(FireDB, `cars/${id}/parts`);

    useEffect(() => {
        setPartNumber(parts.length);
        if(id) {
            const fetchImagesData = query(carPartsCollectionRef, orderBy('timestamp', 'asc'));
            const onsub = onSnapshot(fetchImagesData, (snapshot) => {
                setParts(snapshot.docs.map((doc) => ({id: doc.id, ...doc.data()})));
            });
            return onsub;
        }
    }, [id]);

    useEffect(() => {
        if (parts.length > 0){
            let partNumber = [];
            const lastCar = parts.pop();
            const lastCarIdNumber = lastCar.partCardId;
            partNumber.push(lastCarIdNumber);
            setPartNumber(Number (partNumber) + 1);
            setPartTitle(`${id} - kazkas`);
        } else{
            setPartNumber(1);
            setPartTitle(`${id} - kazkas`);
        }
    }, [parts]);

    const createNewCard = async (event) => {
        event.preventDefault();
        const newCard = {partCardId: partNumber, partTitle: partTitle, timestamp: serverTimestamp()};
        await addDoc(carPartsCollectionRef,newCard);
        setPartNumber('');
    };

    const handleDeleteCard = async (partid) => {
        const cardDoc = doc(FireDB, `cars/${id}/parts`, partid);
        await deleteDoc(cardDoc);
    };
    console.log('parts: ', parts);
   

    return (
        <DashboardFrame
            user={user}
            logoutUser={logoutUser}
        >
            <D_Content title='Automobiliai'>
                <SingleCard  title='Automobilio detalės'>
                    <div className='carParts-container'>
                        <button className='add-new-button' type='button' onClick={createNewCard}>Nauja detalė</button>
                        <span>{parts.length > 0 ? (`Auto detalės : ${parts.length}`) : ('Dar nepridėta autodetalių.')}</span>
                        {
                            parts.length > 0 &&
                            [...parts].reverse().map((part) => (
                                <div key={part.id} className='carParts-gallery'>
                                    <div className='first-col'>
                                        <ShowQRcode car={'kazkos auto'} part={part.partTitle}/>
                                        <p> auto id: {id} </p>
                                        <p> {part.partCardId}. {part.partTitle} </p>
                                        <button className='delete-button' type='button' onClick={() => handleDeleteCard(part.id)}>ištrinti detale</button>
                                    </div>
                                    <PartUploadImg carId={id} part={part}/>
                                    <ShowPartGallery carId={id} part={part}/>
                                </div>
                            ))
                        }
                    </div>
                </SingleCard>
            </D_Content>
        </DashboardFrame>
    );
};

export default CarParts;