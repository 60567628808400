import React from 'react';

const CardWithButton = ({ children, cardTitle, buttonTitle }) => {
    return (
        <div className='cardWithButton-container'>
            <div className='cardWithButton-title'>
                <span>{cardTitle}</span>
                <button type='button' className='cardButton'>{buttonTitle}</button>
            </div>
            <div className='cardWithButton-content'>
                {children}
            </div>
        </div>
    );
};

export default CardWithButton;