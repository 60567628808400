import { collection, doc, onSnapshot } from 'firebase/firestore';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { FireDB } from '../../../../server/firebase-config';

const PrintListSenderReceiver = ({ senderID, receiverID }) => {
    const [sender, setSender] = useState([{}]);
    const [receiver, setReseiver] = useState([{}]);

    const companysCollectionRef =  collection(FireDB, 'companys');

    useEffect(() => {
        if(senderID){
            const showData = onSnapshot(doc(companysCollectionRef, senderID), (doc) => {
                setSender(doc.data());
            });
            return showData;
        }
    }, [senderID]);

    useEffect(() => {
        if(receiverID){
            const showData = onSnapshot(doc(companysCollectionRef, receiverID), (doc) => {
                setReseiver(doc.data());
            });
            return showData;
        }
    }, [receiverID]);

    return (
        <div>
            {
                sender &&

                <ul>
                    <li><u>Siuntėjas</u></li>
                    <li>{sender.companyName}</li>
                    <li>{sender.adress}, {sender.city}</li>
                    <li>{sender.country}</li>
                    <li>Įm. kodas: {sender.companyCode}</li>
                </ul>
            }
            {
                receiver &&

                <ul>
                    <li><u>Gavėjas</u></li>
                    <li>{receiver.companyName}</li>
                    <li>{receiver.adress}, {receiver.city}</li>
                    <li>{receiver.country}</li>
                    <li>Įm. kodas: {receiver.companyCode}</li>
                </ul>
            }
        </div>
    );
};

export default PrintListSenderReceiver;