import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useUserContext } from "../../../../context/UserAuthContext";
import DashboardFrame from "../../../../components/DashboardFrame";
import D_Content from "../../../../components/DashboardFrame/D_Content";
import SingleCard from "../../../../components/DashboardFrame/D_Content/SingleCard";
import { FireDB } from "../../../../server/firebase-config";
import { carsCollectionRef } from "../../../../services/collectionsRef";
import { doc, onSnapshot, updateDoc } from "firebase/firestore";
import AddCarImages from "../AddCarImages";
import CarImagesGallery from "../CarImagesGallery";
import {yearsData} from '../../../../data/YearsData';
import { todayDate } from "../../../../services/todayDate";

const UpdateCar = () => {
  const { user, logoutUser } = useUserContext();
  const { id } = useParams();
  const [updateCar, setUpdateCar] = useState({});

  const [updateBrand, setUpdateBrand] = useState("nenustatyta");
  const [updateModel, setUpdateModel] = useState("nenustatyta");
  const [updateYear, setUpdateYear] = useState(0);
  const [updateFuelType, setUpdateFuelType] = useState("nenustatyta");
  const [updateEngineWorkingVolume, setUpdateEngineWorkingVolume] =
    useState("nenustatyta");
  const [updateEngineCode, setUpdateEngineCode] = useState("nenustatyta");
  const [updateEngineKw, setUpdateEngineKw] = useState(0);
  const [updateEngineWorking, setUpdateEngineWorking] = useState("nenustatyta");
  const [updateGearBoxType, setUpdateGearBoxType] = useState("nenustatyta");
  const [updateGearBoxCode, setUpdateGearBoxCode] = useState("nenustatyta");
  const [updateKm, setUpdateKm] = useState(0);
  const [updateCarRegNr, setUpdateCarRegNr] = useState("nenustatyta");
  const [updateChassiNr, setUpdateChassiNr] = useState("nenustatyta");
  const [updateWeight, setUpdateWeight] = useState(0);
  const [updateCarColor, setUpdateCarColor] = useState("nenustatyta");
  const [updateCarPrice, setUpdateCarPrice] = useState(0);
  const [updatePriceInCurrency, setUpdatePriceInCurrency] =
    useState("nenustatyta");
  const [updateAddedInfo, setUpdateAddedInfo] = useState("nenustatyta");
  const [updateCarStatus, setUpdateCarStatus] = useState("nenustatyta");
  const [updateCarCustomer, setUpdateCarCustomer] = useState("nenustatyta");
  const [updateSteeringWheelPosition, setUpdateSteeringWheelPosition] =
    useState("nenustatyta");
  const [updateCarComments, setUpdateCarComments] = useState("nenustatyta");
  const [updateShowDeleteButton, setUpdateShowDeleteButton] = useState(false);
  
  const navigate = useNavigate();

  useEffect(() => {
    const showData = onSnapshot(doc(carsCollectionRef, id), (doc) => {
      setUpdateCar(doc.data());
    });
    return showData;
  }, [id]);

  useEffect(() => {
    if (id) {
      setUpdateBrand(updateCar.brand);
      setUpdateModel(updateCar.model);
      setUpdateYear(updateCar.year);
      setUpdateFuelType(updateCar.fuelType);
      setUpdateEngineWorkingVolume(updateCar.engineWorkingVolume);
      setUpdateEngineCode(updateCar.engineCode);
      setUpdateEngineKw(updateCar.engineKw);
      setUpdateEngineWorking(updateCar.engineWorking);
      setUpdateGearBoxType(updateCar.gearBoxType);
      setUpdateGearBoxCode(updateCar.gearBoxCode);
      setUpdateKm(updateCar.km);
      setUpdateCarRegNr(updateCar.carRegNr);
      setUpdateChassiNr(updateCar.chassiNr);
      setUpdateWeight(updateCar.weight);
      setUpdateCarColor(updateCar.carColor);
      setUpdateCarPrice(updateCar.carPrice);
      setUpdatePriceInCurrency(updateCar.priceInCurrency);
      setUpdateAddedInfo(updateCar.addedInfo);
      setUpdateCarStatus(updateCar.carStatus);
      setUpdateCarCustomer(updateCar.carCustomer);
      setUpdateSteeringWheelPosition(updateCar.steeringWheelPosition);
      setUpdateCarComments(updateCar.carComments);
      setUpdateShowDeleteButton(updateCar.showDeleteButton);
    } else {
      setUpdateBrand("");
      setUpdateModel("");
      setUpdateYear("");
      setUpdateFuelType("");
      setUpdateEngineWorkingVolume("");
      setUpdateEngineCode("");
      setUpdateEngineKw("");
      setUpdateEngineWorking("");
      setUpdateGearBoxType("");
      setUpdateGearBoxCode("");
      setUpdateKm("");
      setUpdateCarRegNr("");
      setUpdateChassiNr("");
      setUpdateWeight("");
      setUpdateCarColor("");
      setUpdateCarPrice("");
      setUpdatePriceInCurrency("");
      setUpdateAddedInfo("");
      setUpdateCarStatus("");
      setUpdateCarCustomer("");
      setUpdateSteeringWheelPosition("");
      setUpdateSteeringWheelPosition("");
    }
  }, [id, updateCar]);

  const handleUpdateCarinfo = async (
    id,
    brand,
    model,
    year,
    fuelType,
    engineWorkingVolume,
    engineCode,
    engineKw,
    engineWorking,
    gearBoxType,
    gearBoxCode,
    km,
    carRegNr,
    chassiNr,
    weight,
    carColor,
    carPrice,
    priceInCurrency,
    addedInfo,
    carStatus,
    carCustomer,
    steeringWheelPosition,
    carComments,
    showDeleteButton
  ) => {
    const carDocRef = doc(FireDB, "cars", id);
    const newCarData = {
      brand,
      model,
      year,
      fuelType,
      engineWorkingVolume,
      engineCode,
      engineKw,
      engineWorking,
      gearBoxType,
      gearBoxCode,
      km,
      carRegNr,
      chassiNr,
      weight,
      carColor,
      carPrice,
      priceInCurrency,
      addedInfo,
      carStatus,
      carCustomer,
      steeringWheelPosition,
      carComments,
      date: updateCar.date,
      update: todayDate(),
      showDeleteButton,
    };
    await updateDoc(carDocRef, newCarData);
    navigate("/dashboard/cars");
  };

  return (
    <DashboardFrame user={user} logoutUser={logoutUser}>
      <D_Content title='Automobiliai'>
        <SingleCard title='Atnaujinti auto info'>
          <div className='update-car-info'>
            <div className='car-content'>
              <div className='left-box'>
                <AddCarImages carId={id} />
                <CarImagesGallery carId={id} />
              </div>
              <div className='right-box'>
                <ul className='car-info-table'>
                  <li>Anr: {updateCar.carIdNr}</li>
                  <li>
                    <label>Auto marke</label>
                    <input
                      type='text'
                      id='brand'
                      name='brand'
                      value={updateBrand}
                      onChange={(event) => setUpdateBrand(event.target.value)}
                      className=''
                    />
                  </li>
                  <li>
                    <label>Modelis</label>
                    <input
                      type='text'
                      id='model'
                      name='model'
                      value={updateModel}
                      onChange={(event) => setUpdateModel(event.target.value)}
                      className=''
                    />
                  </li>
                  <li>
                    <label>Metai</label>
                    <select
                      type='number'
                      id='year'
                      name='year'
                      value={updateYear}
                      onChange={(event) =>
                        setUpdateYear(event.target.value)
                      }
                      className=''
                    >
                        {
                            yearsData && yearsData.map((year, index) => (
                                <option value={year} key={index}>{year}</option>
                            ))
                        }
                    </select>
                  </li>
                  <li>
                    <label>Variklis</label>
                    <select
                      type='text'
                      id='fuelType'
                      name='fuelType'
                      value={updateFuelType}
                      onChange={(event) =>
                        setUpdateFuelType(event.target.value)
                      }
                      className=''
                    >
                      <option value='benzinas'>Benzinas</option>
                      <option value='benzinas / elektra'>Benzinas / Elektra</option>
                      <option value='benzinas / dujos'>Benzinas / Dujos</option>
                      <option value='bioetanolis'>Bioetanolis</option>
                      <option value='dyzelinas'>Dyzelinas</option>
                      <option value='dyzelinas / elektra'>Dyzelinas / Elektra</option>
                      <option value='dujos'>Dujos</option>
                      <option value='dujos / elektra'>Dujos / Elektra</option>
                      <option value='elektra'>Elektra</option>
                      <option value='kita'>Kita</option>
                    </select>
                  </li>
                  <li>
                    <label>Darbinis tūris</label>
                    <input
                      type='text'
                      id='engineWorkingVolume'
                      name='engineWorkingVolume'
                      value={updateEngineWorkingVolume}
                      onChange={(event) =>
                        setUpdateEngineWorkingVolume(event.target.value)
                      }
                      className=''
                    />
                  </li>
                  <li>
                    <label>Kodas</label>
                    <input
                      type='text'
                      id='engineCode'
                      name='engineCode'
                      value={updateEngineCode}
                      onChange={(event) =>
                        setUpdateEngineCode(event.target.value)
                      }
                      className=''
                    />
                  </li>
                  <li>
                    <label>Kw</label>
                    <input
                      type='number'
                      id='engineKw'
                      name='engineKw'
                      value={updateEngineKw}
                      onChange={(event) =>
                        setUpdateEngineKw(event.target.value)
                      }
                      className=''
                    />
                  </li>
                  <li>
                    <label>Ar buvo užvestas ?</label>
                    <select
                      type='text'
                      id='engineWorking'
                      name='engineWorking'
                      value={updateEngineWorking}
                      onChange={(event) =>
                        setUpdateEngineWorking(event.target.value)
                      }
                      className=''
                    >
                      <option value='taip'>Taip</option>
                      <option value='ne'>Ne</option>
                    </select>
                  </li>
                  <li>
                    <label>Pavarų dėžė</label>
                    <select
                      type='text'
                      id='gearBoxType'
                      name='gearBoxType'
                      value={updateGearBoxType}
                      onChange={(event) =>
                        setUpdateGearBoxType(event.target.value)
                      }
                      className=''
                    >
                      <option value='automatinė'>Automatinė</option>
                      <option value='mechaninė'>Mechaninė</option>
                    </select>
                  </li>
                  <li>
                    <label>P. dėžės kodas</label>
                    <input
                      type='text'
                      id='gearBoxCode'
                      name='gearBoxCode'
                      value={updateGearBoxCode}
                      onChange={(event) =>
                        setUpdateGearBoxCode(event.target.value)
                      }
                      className=''
                    />
                  </li>
                  <li>
                    <label>Rida</label>
                    <input
                      type='number'
                      id='km'
                      name='km'
                      value={updateKm}
                      onChange={(event) => setUpdateKm(event.target.value)}
                      className=''
                    />
                  </li>
                  <li>
                    <label>Reg.nr</label>
                    <input
                      type='text'
                      id='carRegNr'
                      name='carRegNr'
                      value={updateCarRegNr}
                      onChange={(event) =>
                        setUpdateCarRegNr(event.target.value)
                      }
                      className=''
                    />
                  </li>
                  <li>
                    <label>Chasi.nr</label>
                    <input
                      type='text'
                      id='chassiNr'
                      name='chassiNr'
                      value={updateChassiNr}
                      onChange={(event) =>
                        setUpdateChassiNr(event.target.value)
                      }
                      className=''
                    />
                  </li>
                  <li>
                    <label>Svoris</label>
                    <input
                      type='number'
                      id='weight'
                      name='weight'
                      value={updateWeight}
                      onChange={(event) => setUpdateWeight(event.target.value)}
                      className=''
                    />
                  </li>
                  <li>
                    <label>Spalva</label>
                    <input
                      type='text'
                      id='carColor'
                      name='carColor'
                      value={updateCarColor}
                      onChange={(event) =>
                        setUpdateCarColor(event.target.value)
                      }
                      className=''
                    />
                  </li>
                  <li>
                    <label>Kaina</label>
                    <input
                      type='number'
                      id='carPrice'
                      name='carPrice'
                      value={updateCarPrice}
                      onChange={(event) =>
                        setUpdateCarPrice(event.target.value)
                      }
                      className=''
                    />
                  </li>
                  <li>
                    <label>Valiuta</label>
                    <select
                      type='text'
                      id='priceInCurrency'
                      name='priceInCurrency'
                      value={updatePriceInCurrency}
                      onChange={(event) =>
                        setUpdatePriceInCurrency(event.target.value)
                      }
                      className=''
                    >
                        <option value='eur'>EUR</option>
                        <option value='sek'>SEK</option>
                    </select>
                  </li>
                  <li>
                    <label>Pridėjo info</label>
                    <input
                      type='text'
                      id='addedInfo'
                      name='addedInfo'
                      value={updateAddedInfo}
                      onChange={(event) =>
                        setUpdateAddedInfo(event.target.value)
                      }
                      className=''
                    />
                  </li>
                  <li>
                    <label>Statusas</label>
                    <input
                      type='text'
                      id='carStatus'
                      name='carStatus'
                      value={updateCarStatus}
                      onChange={(event) =>
                        setUpdateCarStatus(event.target.value)
                      }
                      className=''
                    />
                  </li>
                  <li>
                    <label>Klientas</label>
                    <input
                      type='text'
                      id='carCustomer'
                      name='carCustomer'
                      value={updateCarCustomer}
                      onChange={(event) =>
                        setUpdateCarCustomer(event.target.value)
                      }
                      className=''
                    />
                  </li>
                  <li>
                    <label>Vairo padėtis</label>
                    <select
                      type='text'
                      id='steeringWheelPosition'
                      name='steeringWheelPosition'
                      value={updateSteeringWheelPosition}
                      onChange={(event) =>
                        setUpdateSteeringWheelPosition(event.target.value)
                      }
                    >
                      <option value='kairėje'>Kairėje</option>
                      <option value='dešinėje'>Dešinėje</option>
                    </select>
                  </li>
                  <li>
                    <label>Komentarai</label>
                    <input
                      type='text'
                      id='carComments'
                      name='carComments'
                      value={updateCarComments}
                      onChange={(event) =>
                        setUpdateCarComments(event.target.value)
                      }
                      className=''
                    />
                  </li>
                  <li>
                    <label>Ištrinimo funk.</label>
                    <select
                      type='text'
                      id='showDeleteButton'
                      name='showDeleteButton'
                      value={updateShowDeleteButton}
                      onChange={(event) =>
                        setUpdateShowDeleteButton(event.target.value)
                      }
                    >
                      <option value='true'>Galima ištrinti</option>
                      <option value='false'>Saugoma</option>
                    </select>
                  </li>
                </ul>
              </div>
            </div>
            <button
              type='button'
              className='update-button'
              onClick={() =>
                handleUpdateCarinfo(
                  id,
                  updateBrand,
                  updateModel,
                  updateYear,
                  updateFuelType,
                  updateEngineWorkingVolume,
                  updateEngineCode,
                  updateEngineKw,
                  updateEngineWorking,
                  updateGearBoxType,
                  updateGearBoxCode,
                  updateKm,
                  updateCarRegNr,
                  updateChassiNr,
                  updateWeight,
                  updateCarColor,
                  updateCarPrice,
                  updatePriceInCurrency,
                  updateAddedInfo,
                  updateCarStatus,
                  updateCarCustomer,
                  updateSteeringWheelPosition,
                  updateCarComments,
                  updateShowDeleteButton
                )
              }
            >
              Atnaujinti
            </button>
            <h6 className='car-id'>auto id : {id}</h6>
          </div>
        </SingleCard>
      </D_Content>
    </DashboardFrame>
  );
};

export default UpdateCar;
