import { collection, onSnapshot, query } from 'firebase/firestore';
import React from 'react';
import { Link } from 'react-router-dom';
import { FireDB } from '../../../../server/firebase-config';
import { useEffect } from 'react';
import { useState } from 'react';


const PartCard = ({ id, part }) => {
    const [images, setImages] = useState([]);

    useEffect(() => {
        const carPartImagesCollRef = collection(FireDB, `cars/${id}/parts/${part.id}/images`);
        const fetchImagesData = query(carPartImagesCollRef);
        const onsub = onSnapshot(fetchImagesData, (snapshot) => {
            setImages(snapshot.docs.map((doc) => ({id: doc.id, ...doc.data()})));
        });
        return onsub;
    }, [id, part]);
  
    return (
        <Link to={`/dashboard/cars/car_part_info/${id}/${part.id}`} className='partCard'>
            <div className='top'>
                <div className='left'>
                    <span>{`Nr.: ${part.partCardId}`}</span>
                    <h1>{part.partTitle}</h1>
                </div>
                <h6>part id: {part.id}</h6>
            </div>
            <ul className='content'>
                {
                    images.length > 0 &&
                        <li>
                            <img src={images[0].partImg[0]} alt='' width='80px' />
                        </li>
                }
                <li>Kodas : xx</li>
                <li>Nuotraukos : {images.length > 0 ? images.length : 0}</li>
               
                
            </ul>
        </Link>
    );
};

export default PartCard;