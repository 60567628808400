import { addDoc, collection, doc, onSnapshot, serverTimestamp } from 'firebase/firestore';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import DashboardFrame from '../../../../components/DashboardFrame';
import D_Content from '../../../../components/DashboardFrame/D_Content';
import SingleCard from '../../../../components/DashboardFrame/D_Content/SingleCard';
import { useUserContext } from '../../../../context/UserAuthContext';
import { FireDB } from '../../../../server/firebase-config';
import { calculatedCheckAmountInEuro } from '../src';
import PartsList from '../PartsList';
import { packingsListsCollectionRef } from '../../../../services/collectionsRef';
import { partInitialState } from '../../../../services/initialStates';

const AddParts = () => {
    const { user, logoutUser } = useUserContext();
    const { id } = useParams();
    const [packinglist, setPackinglist] = useState({});
    const [part, setPart] = useState(partInitialState);

    const changePartInput = (event) => {
        const value = event.target.value;
        setPart({
            ...part, [event.target.name] : value
        });
    };

    useEffect(() => {
        const unsub = onSnapshot(doc(packingsListsCollectionRef, id), (doc) => {
            setPackinglist(doc.data());
        });
        return unsub;
    },[id]);

    const partsCollectionRef = collection(FireDB, `packinglists/${id}/parts`);

    const handleAddParts = async (event) => {
        event.preventDefault();
        const newPart = {
            partName: part.partName, 
            code: part.code, 
            units: part.units, 
            partWeight: part.partWeight, 
            timestamp: serverTimestamp()
        };
        await addDoc(partsCollectionRef, newPart);
        setPart(partInitialState);
    };

    return (
        <DashboardFrame
            user={user}
            logoutUser={logoutUser}
        >
            <D_Content title='Packinglisto / detalės'>
                <SingleCard title='Packinglisto - detalės'>
                    <div className='addParts-container'>
                        <div className='addParts-links'>
                            <Link to='/dashboard/packinglists' className='link'>
                                atgal
                            </Link>
                            <Link to={`/dashboard/packinglists/print_saved_car_parts/${id}`} className='link'>
                                Print
                            </Link>
                        </div>
                        <span>Sudarykite packinglist <u>id: {id}</u> sąrašą .</span>
                        <span>Pavadinimas : <strong><em>{packinglist.title}</em></strong></span> 
                        <span>Čekio suma: {packinglist.checkAmount} SEK </span>
                        <span> {calculatedCheckAmountInEuro(packinglist.checkAmount, packinglist.rateOfExchange)} EUR, pagal keitimo kursą: {packinglist.rateOfExchange}</span>
                    </div>

                    <form onSubmit={handleAddParts} className='plPartsAddForm'>
                        <input type='text' className='code' name='code' placeholder='Kodas' value={part.code} onChange={changePartInput} required/>
                        <input type='text' className='partName' name='partName' placeholder='Detalės pavadinimas' value={part.partName} onChange={changePartInput} required/>
                        <input type='number' className='units' name='units'  placeholder='Vienetai' value={part.units} onChange={changePartInput} required/>
                        <input type='number' className='units' name='partWeight' placeholder='Svoris' value={part.partWeight} onChange={changePartInput} required/>
                        <button type='submit' className='plCarParts-button'> + </button>
                    </form>
                    <PartsList
                        id={id}
                        plCheckAmount={packinglist.checkAmount}
                        plRateOfExchange={packinglist.rateOfExchange}
                    />
                </SingleCard>
            </D_Content>
        </DashboardFrame>
    );
};

export default AddParts;