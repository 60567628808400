import React from 'react';
import { Link } from 'react-router-dom';
import { collection, deleteDoc, doc, onSnapshot, query } from 'firebase/firestore';
import { useEffect } from 'react';
import { useState } from 'react';
import { FireDB } from '../../../../server/firebase-config';

const CarCard = ({ car, carsCollectionRef }) => {
    const [images, setImages] = useState([]);
    const [smallGallery, setSmallGallery] = useState([]);
    const [carParts, setCarParts] = useState([]);
    const [selectedImg, setSelectedImg] = useState(0);

    const imagesCollectionRef = collection(FireDB, `cars/${car.id}/images`);

    useEffect(() => {
        if(car.id) {
            const fetchImagesData = query(imagesCollectionRef);
            const onsub = onSnapshot(fetchImagesData, (snapshot) => {
                setImages(snapshot.docs.map((doc) => ({id: doc.id, ...doc.data()})));
            });
            return onsub;
        }
    }, [car.id]);

    useEffect(() => {
        const carPartsCollectionRef = collection(FireDB, `cars/${car.id}/parts`);
        const fetchPartsData = query(carPartsCollectionRef);
            const onsub = onSnapshot(fetchPartsData, (snapshot) => {
                setCarParts(snapshot.docs.map((doc) => ({id: doc.id, ...doc.data()})));
        });
        return onsub;

    }, [car.id]);

    useEffect(() => {
        let newGallery = [];

        if(images.length > 0){
            let maxImages = images.length < 3 ? images.length : 3;
            for(let i = 0; i < maxImages; i++){
                newGallery.push(images[i]);
            }
        }
        setSmallGallery(newGallery);
    }, [images]);
    
    const deleteCar = async (id) =>{
        const docRef = doc(carsCollectionRef, id);
        await deleteDoc(docRef);
    };

    //images

    //display images length
    const countImages = (images) => {
        return images.length;
    };
    const imagesLength = countImages(images || 0);

   

   
    return (
        <ul className='car-card-container'>
            <li className='id-box'>
                <h2><span>Nr: </span>{car.carIdNr}</h2>
                <h2><span>Foto: {imagesLength} vnt</span></h2>
                <span>{car.id}</span>
            </li>
            <li className='img-box'>
                {
                    smallGallery.length > 0 ? 
                        <img src={smallGallery[selectedImg].imgName} alt=''/> :
                        <img src='http://grumin.lt/web-images/placeholder.jpg' alt='ucarparts img' />
                } 
            </li>
            <li className='images'>
                {
                    smallGallery.length > 1 &&
                    smallGallery.map((img, index) => (
                        <img src={img?.imgName} alt='ucarparts img' key={img?.id} onClick={() => setSelectedImg(index)} />
                    )) 
                } 
            </li>
            <li className='car-info-first-box'>
                <h1>{car.brand} {car.model} {car.year} m.</h1>
                <div className='info-layout'>
                    <div className='line'>Variklis: {car.fuelType}</div>
                    <div className='line'>Kodas: {car.engineCode}</div>
                    <div className='line'>Pavarų dėžė: {car.gearBoxType}</div>
                    <div className='line'>Statusas: {car.carStatus}</div>
                    <div className='line'>Rezervacija: {car.carCustomer}</div>
                    <div className='line'>Pridėjo info: {car.addedInfo}</div>
                    <div className='line'>Data: {car.date}</div>
                    <div className='line'>Išimtų detalių: { carParts.length > 0 ? carParts.length : '0'}</div>
                </div>
            </li>
            <li className='action'>
              
                <Link to={`/dashboard/cars/car_page/${car.id}`} className='actionButtons'>Info</Link>
                <Link to={`/dashboard/cars/update_car/${car.id}`} className='actionButtons'>Atnaujinti</Link>
                <Link to={`/dashboard/cars/car_parts_collection/${car.id}`} className='actionButtons'>Detalės</Link>
                <Link to={`/dashboard/cars/car_parts_order/${car.id}`} className='actionButtons'>Detalės test</Link>
                {
                    car.showDeleteButton ? 
                        <button className='actionButtons' type='button' onClick={() => deleteCar(car.id)}>Ištrinti</button> :  
                        <button className='actionButtons' >Saugoma</button>
                }
                
            </li>
        </ul>
    );
};

export default CarCard;