import React from 'react';
import { Link } from 'react-router-dom';
import img from '../../../images/logo.png';
import userImg from '../../../images/profile.jpg';
import { FaBars } from "react-icons/fa";
import LogoImage from '../../LogoImg';

const D_Header = ({ user, logoutUser, showSidebar }) => {
    
    return (
        <header className='dashboard-header'>
            <nav className='dashboard-navigation'>
                <div className='navigation-left-box'>
                    <Link to='/dashboard'>
                        <LogoImage url={img} title='logo' cName='logo-style'/>
                    </Link>
                    <Link to='#' onClick={showSidebar} className='dashboard-menu-bars'>
                        <FaBars/>
                    </Link>
                </div>
                <ul className='navigation-right-box'>
                    <li>
                        <LogoImage url={userImg} title='user logo' cName='userImage' />
                    </li>
                    <li className='userEmail'>
                        <h4>
                            {user.email}
                        </h4>
                        <small>Super Admin</small>
                    </li>
                    <li className='logout-button-container'>
                        <button onClick={() => logoutUser() } className='logout-button'>Atsijungti</button>
                    </li>
                </ul>
            </nav>
        </header>
    );
};

export default D_Header;