import React from 'react';
import { Link } from 'react-router-dom';
import LogoImage from '../../LogoImg';
import userImg from '../../../images/profile.jpg';

const D_active_Sidebar = ({ DashboardSidebarMenu, user, logoutUser }) => {
    return (
        <div className='sidebar-menu-with-title active'>
            <ul className='sidebar-menu-items'>
                {DashboardSidebarMenu.map((item, index) => {
                    return (
                    <li key={index}>
                        <Link to={item.path} className={item.cName}>
                        <span>{item.icon}</span>
                            {item.title}
                        </Link>
                    </li>
                    );
                })}
                
                <li className='sidebar-menu-userEmail'>
                    <LogoImage url={userImg} title='user logo' cName='userImage' />
                    <h4>
                        {user.email}
                    </h4>
                    <small>Super Admin</small>
                    <button onClick={() => logoutUser()} className='logout-button'>Atsijungti</button>
                </li>
            </ul>
        </div>
    );
};

export default D_active_Sidebar;