import React, { useRef, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { useUserContext } from '../../../context/UserAuthContext';
import logoImg from '../../../images/logo.png';

const Login = () => {
    const emailRef = useRef();
    const psdRef = useRef();
    const [emptyFields, setEmptyFields] = useState('');
    const { user, signInUser, error } = useUserContext();

    const handleLogin = (event) => {
        event.preventDefault();
        const email = emailRef.current.value;
        const password = psdRef.current.value;
        if(email === '' || password === ''){
            setEmptyFields('Tušti laukeliai !');
        } else if(email && password){
            signInUser(email, password);
            emailRef.current.value = '';
            psdRef.current.value = '';
        } else {
            setEmptyFields('');
        }
    };

    return (
        <div className='login-container'>
            {
                user ? <Navigate to='/dashboard' /> : 
                    <div className='login-form'>
                        <img src={logoImg} alt='logo' />
                        {error && <p>{`${emailRef.current.value} - ${error}`}</p>}
                        {emptyFields === '' ? false : <p>Klaida. Tušti laukeliai !</p>}
                        <form onSubmit={handleLogin}>
                            <input type='email' ref={emailRef} placeholder='El.paštas'  required autoComplete='true'/>
                            <input type='password' ref={psdRef} placeholder='Slaptažodis'  required autoComplete='true'/>
                            <button type='submit'>Prisijungti</button>
                        </form>
                        <ul>
                            <li><Link to='/'>Pagrindinis</Link></li>
                            {/* <li><Link to='/register'>Register page</Link></li> */}
                        </ul>  
                    </div>
            }
        </div>
    );
};

export default Login;