import React from 'react';
import { useEffect } from 'react';

const UpdateItem = ({ 
        part, 
        newPartName, 
        setNewPartName, 
        newCode,
        setNewCode,
        newUnits,
        setNewUnits, 
        newWeight,
        setNewWeight,
        handleUpdatePartData
    }) => {
    
    useEffect(() => {
        if(part) {
            setNewPartName(part.partName);
            setNewCode(part.code);
            setNewUnits(part.units);
            setNewWeight(part.partWeight);
        }
    }, [part]);

    return (
        <ul className='updateItem'>
            <li>
                <label>Kodas</label>
                <input type='text' name='code' value={newCode}  placeholder={newCode} onChange={(event) => setNewCode(event.target.value)} required/>
            </li>
            <li>
                <label>Detalės pavadinimas</label>
                <input type='text' name='partName' value={newPartName}  placeholder={newPartName} onChange={(event) => setNewPartName(event.target.value)} required/>
            </li>
            <li>
                <label>Vienetai</label>
                <input type='number' name='units' value={newUnits}  placeholder={newUnits} onChange={(event) => setNewUnits(event.target.value)} required/>
            </li>   
            <li>
                <label>Svoris</label>
                <input type='number' name='partWeight' value={newWeight}  placeholder={newWeight} onChange={(event) => setNewWeight(event.target.value)} required/>
            </li>
            <li>
                <button type='button' onClick={() => handleUpdatePartData(part.id)}>Atnaujinti</button>
            </li>
        </ul>
    );
};

export default UpdateItem;