import React, {useEffect, useState} from 'react';
import { FireDB } from '../../../../server/firebase-config';
import { collection, deleteDoc, doc, onSnapshot, query } from 'firebase/firestore';
import { AiTwotoneDelete } from 'react-icons/ai';

const CarImagesGallery = ({ carId }) => {
    const [images, setImages] = useState([]);

    const imagesCollectionRef = collection(FireDB, `cars/${carId}/images`);

    useEffect(() => {
        if(carId) {
            const fetchImagesData = query(imagesCollectionRef);
            const onsub = onSnapshot(fetchImagesData, (snapshot) => {
                setImages(snapshot.docs.map((doc) => ({id: doc.id, ...doc.data()})));
            });
            return onsub;
        }
    }, [carId]);

    const deleteImg = async (id) => {
        const carImgDocRef = doc(imagesCollectionRef, id);
        await deleteDoc(carImgDocRef);
    };


    return (
        <div className='img-collection'>
         {
            images.length > 0 ? 
            images.map((image) => (
                <div key={image.id} className='img-box'>
                    <img src={image.imgName} alt=''/>
                    <button type='button' onClick={() => deleteImg(image.id)}><AiTwotoneDelete /></button>
                </div>
            )) : <p>Galerijoje nėra automobilio nuotraukų.</p>
         }
        </div>
    );
};

export default CarImagesGallery;