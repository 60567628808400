import { addDoc, onSnapshot, query, serverTimestamp } from 'firebase/firestore';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { companysCollectionRef, packingsListsCollectionRef } from '../../../../services/collectionsRef';
import { plInitialState } from '../../../../services/initialStates';

const AddNewPackinglist = () => {
    const [newPackinglistTitle, setNewPackinglistTitle] = useState(plInitialState);
    const [companys, setCompanys] = useState([]);

    useEffect(() => {
        const fetchCompanysData = query(companysCollectionRef);
        const onsub = onSnapshot(fetchCompanysData, (snapshot) => {
            setCompanys(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id})));
        });
        onsub;
    }, []);

    const today = new Date();
    const showDate = (value) => {
        let date;
        let month = value.getMonth() + 1;
        let day = value.getDate();
        let hours = value.getHours();
        let minutes = value.getMinutes();

        if (month < 10) {
            month = `0${month}`;
        }
        if (day < 10) {
            day = `0${day}`;
        }
        if (hours < 10) {
            hours = `0${hours}`;
        }
        if (minutes < 10) {
            minutes = `0${minutes}`;
        }

        date = `${value.getFullYear()}/${month}/${day} ${hours}:${minutes}`;
        return date;
    };

    let newDate = showDate(today);
    
    const handleChangePaleteTitle = (event) => {
        const value = event.target.value;
        setNewPackinglistTitle({
            ...newPackinglistTitle, [event.target.name] : value
        }); 
    };

    const handleAddPackinglist = async (event) => {
        event.preventDefault();

        const newPackinglist = {
            title: newPackinglistTitle.title,
            sendTo: newPackinglistTitle.sendTo,
            checkAmount: newPackinglistTitle.checkAmount,
            rateOfExchange: newPackinglistTitle.rateOfExchange,
            checkNumber: newPackinglistTitle.checkNumber,
            userName: newPackinglistTitle.userName,
            senderID: newPackinglistTitle.senderID,
            receiverID: newPackinglistTitle.receiverID,
            date: newDate,
            timestamp: serverTimestamp()
        };
        
        await  addDoc(packingsListsCollectionRef, newPackinglist);
        setNewPackinglistTitle(plInitialState);
    };

    return (
        <form onSubmit={handleAddPackinglist}  className='addPackinglist-form'>
            <div className='addPackinglist-box'>
                <a href='https://www.lb.lt/lt/pagal-buhalterines-apskaitos-istatyma-formuojami-euro-ir-uzsienio-valiutu-santykiai' target='_blank' rel='noreferrer' >LB eur/sek nuoroda</a>
                <input 
                    type='text' 
                    id='packinglistTitle'
                    name='title'
                    placeholder='Packinglisto pavadinimas ...'
                    value={newPackinglistTitle.title}
                    onChange={handleChangePaleteTitle}
                    required
                />
                <input 
                    type='text' 
                    id='packinglistSendTo'
                    name='sendTo'
                    placeholder='Miestas į kur siunčiate ...'
                    value={newPackinglistTitle.sendTo}
                    onChange={handleChangePaleteTitle}
                    required
                />
                <input 
                    type='number' 
                    id='checkAmount'
                    name='checkAmount'
                    placeholder='Čekio suma'
                    value={newPackinglistTitle.checkAmount}
                    onChange={handleChangePaleteTitle}
                    required
                />
                <input 
                    type='number' 
                    id='rateOfExchange'
                    name='rateOfExchange'
                    placeholder='Eur / Sek kursas'
                    value={newPackinglistTitle.rateOfExchange}
                    onChange={handleChangePaleteTitle}
                    required
                />
                <input 
                    type='text' 
                    id='checkNumber'
                    name='checkNumber'
                    placeholder='Čekio numeris'
                    value={newPackinglistTitle.checkNumber}
                    onChange={handleChangePaleteTitle}
                    required
                />
                <input 
                    type='text' 
                    id='userName'
                    name='userName'
                    placeholder='Vartotojo vardas'
                    value={newPackinglistTitle.userName}
                    onChange={handleChangePaleteTitle}
                    required
                />
                <select name='senderID' value={newPackinglistTitle.senderID} onChange={handleChangePaleteTitle} required>
                    <option>Pasirinkite siuntėją</option>
                    {
                        companys.length > 0 ?
                            companys.map((company) => {
                                return<option value={company.id} key={company.id}>{company.companyName}</option>;
                            }) : <option value='nerasta'>nėra įmonių</option>
                    }
                </select>
                <select name='receiverID' value={newPackinglistTitle.receiverID} onChange={handleChangePaleteTitle} required>
                    <option>Pasirinkite gavėją</option>
                    {
                        companys.length > 0 ?
                            companys.map((company) => {
                                return<option value={company.id} key={company.id}>{company.companyName}</option>;
                            }) : <option value='nerasta'>nėra įmonių</option>
                    }
                </select>
              
                <button type='submit'>Sukurti naują Packinglistą</button>
            </div>
        </form>
    );
};

export default AddNewPackinglist;