import { addDoc, deleteDoc, doc, onSnapshot, orderBy, query, serverTimestamp } from 'firebase/firestore';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { FireDB } from '../../../../server/firebase-config';
import { companysCollectionRef } from '../../../../services/collectionsRef';
import { companyInitialState } from '../../../../services/initialStates';

const Companys = () => {
    const [company, setCompany] = useState(companyInitialState);
    const [companys, setCompanys] = useState([]);

    const inputChange = (event) => {
        const value = event.target.value;
        setCompany({
            ...company, [event.target.name] : value
        });
    };

    useEffect(() => {
        const fetchCompanysData = query(companysCollectionRef, orderBy('timestamp', 'desc'));
        const onsub = onSnapshot(fetchCompanysData, (snapshot) => {
            setCompanys(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id, })));
        });
        onsub;
    }, []);

    const handleAddSender = async (event) => {
        event.preventDefault();
        const newCompany = {
            companyName: company.companyName, 
            adress: company.adress, 
            city: company.city, 
            country: company.country, 
            companyCode: company.companyCode,  
            timestamp: serverTimestamp() 
        };
        await addDoc(companysCollectionRef, newCompany);
        setCompany(companyInitialState);
    };

    const deleteCompany = async (id) => {
        const company = doc(FireDB, 'companys', id);
        await deleteDoc(company);
    };

    return (
        <div className='settings-companys'>
            <form onSubmit={handleAddSender}>
                <input type='text' name='companyName' placeholder='Pavadinimas' value={company.companyName} onChange={inputChange} required/>
                <input type='text' name='adress' placeholder='Gatvė' value={company.adress} onChange={inputChange} required/>
                <input type='text' name='city' placeholder='Miestas' value={company.city} onChange={inputChange} required/>
                <input type='text' name='country' placeholder='Šalis' value={company.country} onChange={inputChange} required/>
                <input type='text' name='companyCode' placeholder='Įm. kodas' value={company.companyCode} onChange={inputChange} required/>
                <button>Pridėti įmonę</button>
            </form>
            <div className='companys-list'>
                {
                    companys.length > 0 ? 
                        companys.map((item) => (
                            <div key={item.id} className='box'>
                               
                                <p>Įmonė: <strong>{item.companyName}</strong></p>
                                <p>Adresas: <strong>{item.adress}</strong></p>
                                <p>Miestas: <strong>{item.city}</strong></p>
                                <p>Šalis: <strong>{item.country}</strong></p>
                                <p>Įm. codas : <strong>{item.companyCode}</strong></p>
                               
                                <button type='button' onClick={() => deleteCompany(item.id)}>ištrinti</button>
                            </div>
                        )) : 'Įmonių sąrašas yra tuščias.'
                }

            </div>
        </div>
    );
};

export default Companys;