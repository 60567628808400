import React, { useState, useEffect } from 'react';
import { useUserContext } from '../../../../context/UserAuthContext';
import DashboardFrame from '../../../../components/DashboardFrame';
import D_Content from '../../../../components/DashboardFrame/D_Content';
import SingleCard from '../../../../components/DashboardFrame/D_Content/SingleCard';
import { useParams } from 'react-router-dom';
import { FireDB } from '../../../../server/firebase-config';
import { addDoc, collection, doc, getDoc, onSnapshot, orderBy, query, serverTimestamp } from 'firebase/firestore';
import PartCard from './PartCard';

const carInitialState = {
    brand: '',
    model: ''
};

const CarPartsCollection = () => {
    const { user, logoutUser } = useUserContext();
    const { id } = useParams();
    const [car, setCar] = useState(carInitialState);
    const [carParts, setCarParts] = useState([]);
    const [partNumber, setPartNumber] = useState('');

    useEffect(() => {
        const carCollectionRef = doc(FireDB, 'cars', id);
        const fetchData = async () => {
            try{
                const response = await getDoc(carCollectionRef);
                if(response.exists()) {
                    setCar(response.data());
                } else {
                    console.log('Document does not exist.');
                }
            }catch(err){
                console.log(err);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const carPartsCollectionRef = collection(FireDB, `cars/${id}/parts`);
        const fetchPartsData = query(carPartsCollectionRef, orderBy('timestamp', 'asc'));
        const onsub = onSnapshot(fetchPartsData, (snapshot) => {
            setCarParts(snapshot.docs.map((doc) => ({id: doc.id, ...doc.data()})));
        });
        return onsub;
    }, []);

    useEffect(() => {
        if (carParts.length > 0){
            let findNumber = carParts[carParts.length - 1];
            // console.log('findNumber :', findNumber);
            setPartNumber(Number(findNumber.partCardId) + 1);
        } else {
            setPartNumber(1);
        }
    }, [carParts]);

    const createNewCard = async (event) => {
        const carPartsCollectionRef = collection(FireDB, `cars/${id}/parts`);
        event.preventDefault();
        const newCard = {partCardId: partNumber, partTitle: 'x detalė', timestamp: serverTimestamp()};
        await addDoc(carPartsCollectionRef, newCard);
       
    };

    return (
        <DashboardFrame
            user={user}
            logoutUser={logoutUser}
        >
            <D_Content title='Automobiliai'>
                <SingleCard  title={`${car.brand} ${car.model} detalės : ${carParts.length > 0 ? carParts.length : 0}`}>
                    <h6>Auto id: {id}</h6>
                    <br />
                    <button className='add-new-button' type='button' onClick={createNewCard}>Nauja detalė</button>
                    <br />
                    <span></span>
                </SingleCard>
                <div className='carParts-collection'>
                    {
                        carParts && 
                        [ ...carParts].reverse().map((part) => (
                            <PartCard key={part.id}  id={id} part={part} />
                        ))
                    }
                </div>
            </D_Content>
        </DashboardFrame>
    );
};

export default CarPartsCollection;