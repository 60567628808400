import React, { createContext, useContext, useState, useEffect } from "react";
import {
//   signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { FireAuth } from "../server/firebase-config";
import { useNavigate } from 'react-router-dom';
// import { protectedUser } from "../server/adminData";

export const UserContext = createContext({});

export const useUserContext = () => {
  return useContext(UserContext);
};

export const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    const unsubscribe = onAuthStateChanged(FireAuth, (res) => {
      if (res) {
        setUser(res);
      } else {
        setUser(null);
      }
      setError("");
      setLoading(false);
    });
    return unsubscribe;
  }, []);

  const registerUser = (email, password) => {
    setLoading(true);
    if(!email || !password){
        setError('Invalid, empty fiels Entry');
    }

    createUserWithEmailAndPassword(FireAuth, email, password)
      .then((res) => {
        localStorage.setItem('ucarparts_user_auth_token', res.user.accessToken);
        navigate('/dashboard');
      })
      .catch((err) => setError(err.message))
      .finally(() => setLoading(false));
  };

  const signInUser = (email, password) => {
    setLoading(true);
    signInWithEmailAndPassword(FireAuth, email, password)
        .then((res) => {
            localStorage.setItem('grumin_user_auth_token', res.user.accessToken);
            navigate('/dashboard');
            // if(email === protectedUser){
            //   navigate('/admin');
            // }else{
            //   navigate('/users');
            // }
        })
        .catch((err) => setError(err.code))
        .finally(() => setLoading(false));
  };

  const logoutUser = () => {
    signOut(FireAuth);
    navigate('/login');
  };

  const forgotPassword = (email) => {
    return sendPasswordResetEmail(FireAuth, email);
  };

  const contextValue = {
    user,
    loading,
    setLoading,
    error,
    signInUser,
    registerUser,
    logoutUser,
    forgotPassword,
  };
  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};