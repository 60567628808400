import React from 'react';
import Frame from '../../../components/Frame';


const Home = () => {
    return (
        <Frame>
           <br />
        </Frame>
    );
};

export default Home;