import React, { useEffect, useState } from 'react';
import QRCode from 'qrcode';

function ShowQRcode({ car, part }) {

    const [srcQrcode, setSrcQrcode] = useState('');

    useEffect(() => {
        QRCode.toDataURL(`Automobilio detalė: ${car.brand} ${part.partTitle}`).then((data) => {
            setSrcQrcode(data);
        });
    },[part]);

    return <img src={srcQrcode} alt='qr codas' />;
}

export default ShowQRcode;