import { addDoc, collection, onSnapshot, query, serverTimestamp } from 'firebase/firestore';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { FireDB } from '../../../../server/firebase-config';
import {GrCheckbox, GrCheckboxSelected } from 'react-icons/gr';

const TestOrder = ({id}) => {
    const [databaseParts, setDatabaseParts] = useState([]);

    const dbPartsCollRef = collection(FireDB, 'partNames');
   
    useEffect(() => {
        const fetchPartsData = query(dbPartsCollRef);
        const onsub = onSnapshot(fetchPartsData, (snapshot) => {
            setDatabaseParts(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        });
        onsub;
    }, []);

    return (
        <div>TestOrder {id}
            {
                databaseParts.length > 0 ? 
                    databaseParts.map((value) => (
                        <Row key={value.id} value={value} id={id}/>
                    ))
                    : 'no data'
            }
        </div>
    );
};

export default TestOrder;

const Row = ({ value, id }) => {
    const [clicked, setClicked] = useState(false);

    const  newPartsCollectionRef = collection(FireDB, `cars/${id}/order`);

    const handleSubmit = async (value) => {
        const newPartDoc = {
            name: value,
            status: 'nenustatyta',
            statusColor: 'gray',
            userAdded: 'Jonas',
            userUpdated: 'Antanas',
            updatedDate: '2023/01/21',
            timestamp: serverTimestamp()
        };
        await addDoc(newPartsCollectionRef, newPartDoc);
        setClicked(true);
    };

    return(
        <form key={value.id}>
            {
                !clicked ?
                    <button type='button' onClick={() => handleSubmit(value.name)}><GrCheckbox /></button>
                    :  <button type='button'><GrCheckboxSelected /></button>
            }
            <input type='text' value={value.name} hidden />
            {!clicked ? <span>{value.name}</span> : <span style={{ textDecoration: 'line-through' }}>{value.name}</span>}

        </form>
    );

};

