import React from 'react';
import { useState } from 'react';
import { carBrandInitialState } from '../../../../services/initialStates';
import { addDoc, collection, deleteDoc, doc, onSnapshot, orderBy, query, serverTimestamp } from 'firebase/firestore';
import { FireDB } from '../../../../server/firebase-config';
import { useEffect } from 'react';

import BrandsList from './BrandsList';

const CarBrands = () => {
    const [carBrand, setCarBrand] = useState(carBrandInitialState);
    const [carBrands, setCarBrands] = useState([]);
    const [emptyFieldsErr, setEmptyFieldsErr] = useState('');

    const carBrandsCollectionRef = collection(FireDB, 'carBrands');

    useEffect(() => {
        const fetchCompanysData = query(carBrandsCollectionRef, orderBy('timestamp', 'desc'));
        const onsub = onSnapshot(fetchCompanysData, (snapshot) => {
            setCarBrands(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id, })));
        });
        onsub;
    }, []);

    const inputChange = (event) => {
        const value = event.target.value;
        setCarBrand({
            ...carBrand, [event.target.name] : value
        });
    };

    const checkBrandName = (value) => {
        let lowerCaseWord = value.toLowerCase();
        let brandDoc = [];
        brandDoc = carBrands.filter(doc => doc.brand === lowerCaseWord);
        if(brandDoc.length > 0){
            return true;
        }
    };

    const handleAddCarBrand = async (event) => {
        event.preventDefault();
        if(carBrand.title === '' || carBrand.title === '-' || carBrand.title === '.' || carBrand.title === '*'  || carBrand.title === '/'){
            setEmptyFieldsErr('Privaloma suvesti markės pavadinimą.');
            setTimeout(() => {
                setEmptyFieldsErr('');
            }, 2000);
        } else {
            if(checkBrandName(carBrand.title) === true) {
                setEmptyFieldsErr('Jau yra tokia markė.');
                setTimeout(() => {
                    setEmptyFieldsErr('');
                }, 2000);
            } else{
                const newCarBrand = {brand: carBrand.title.toLowerCase(), timestamp: serverTimestamp()  };
                await addDoc(carBrandsCollectionRef, newCarBrand);
            }
        }
        setCarBrand(carBrandInitialState);
    };

    const handleDeleteCarBrand =  async (id) => {
        const brandDoc = doc(FireDB, 'carBrands', id );
        await deleteDoc(brandDoc);
    };

    const sortCarBrands = carBrands && carBrands.sort( (a, b) => a.brand.localeCompare(b.brand) );

    return (
        <div className='settings-carBrands'>
            <p style={{ color: '$red' }}>{emptyFieldsErr !== '' &&  emptyFieldsErr}</p>
            <form onSubmit={handleAddCarBrand}>
                <input type='text' name='title' value={carBrand.title} placeholder='Markė' onChange={inputChange} required/>
                <button>Pridėti automobilio marke</button>
            </form>

            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                {
                    carBrands.length > 0 && 
                    sortCarBrands.map((brand) => (
                        <BrandsList key={brand.id} brand={brand} handleDeleteCarBrand={handleDeleteCarBrand} />
                    ))
                }
            </div>
        </div>
    );
};

export default CarBrands;