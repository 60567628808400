import { addDoc, collection, } from 'firebase/firestore';
import React from 'react';
import { useState } from 'react';
import { FireDB, FireStorageDB } from '../../../../server/firebase-config';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { useEffect } from 'react';

const PartUploadImg = ({ id, partid}) => {
    const [partImg, setPartImg] = useState('');
    const [ showButton, setShowButton] = useState(false);   

    useEffect(() => {
        if(partImg !== ''){
            setShowButton(true);
        } else {
            setShowButton(false);
        }
    }, [partImg]);

    const handleImageChange = (event) => {
       
        if (event.target.files[0]){
         //  setImage(event.target.files[0]);
             const handleImagesSubmit = () => {
                 const imageRef = ref(FireStorageDB, `parts/${Date.now()}-${event.target.files[0].name}`);
                 uploadBytes(imageRef, event.target.files[0]).then(() => {
                 getDownloadURL(imageRef).then((url) => {
                     const images = [];
                     if (url) {
                         images.push(url);
                     }
                     setPartImg(images);
                 }).catch((error) => {
                     alert(error.message, 'error  getting the image url');
                 });
                 // setImage(null);
                 }).catch((error) => {
                 alert(error.message);
                 });
             };
             handleImagesSubmit();
            
        }
 
        setTimeout(() => {
             event.target.value = null;
         },2000);
 
    };

    const AddImagesCollection = async (event) => {
        event.preventDefault();
        const newColRef = collection(FireDB, `cars/${id}/parts/${partid}/images`);
        const partImagesUrl = {partImg};
        await addDoc(newColRef, partImagesUrl);
        setPartImg('');
    };
    return (
        <div className='add-part-img-form'>
            <img src={partImg || 'http://grumin.lt/web-images/placeholder.jpg'} alt='' style={{ width: '150px', marginBottom: '30px' }}  />
            <form onSubmit={AddImagesCollection} style={{ marginBottom: '30px' }} >
                {
                    showButton ?  
                        <button type='submit'>prideti</button>
                            :  <input type='file' onChange={handleImageChange} className='custom-file-input'/>
                            
                }
            </form>
        </div>
    );
};

export default PartUploadImg;