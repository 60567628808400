import { collection, deleteDoc, doc, onSnapshot, query } from 'firebase/firestore';
import React from 'react';
import { FireDB } from '../../../../server/firebase-config';
import { useEffect } from 'react';
import { useState } from 'react';
import { AiTwotoneDelete } from 'react-icons/ai';

const ShowPartGallery = ({ id, partid }) => {
    const [images, setImages] = useState([]);

    useEffect(() => {
        if(id && partid){
            const carPartsCollectionRef = collection(FireDB, `cars/${id}/parts/${partid}/images`);
       
            const fetchImagesData = query(carPartsCollectionRef);
            const onsub = onSnapshot(fetchImagesData, (snapshot) => {
                setImages(snapshot.docs.map((doc) => ({id: doc.id, ...doc.data()})));
            });
            return onsub;
        }  
    }, [id]);

    const handleDeletePartImg = async (carId, imgId) => {
        const partImgDoc = doc(FireDB, `cars/${carId}/parts/${partid}/images`, imgId);
        await deleteDoc(partImgDoc);
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', gap: '20px' }}>
            {
                images.length > 0 ? 
                images.map((img) => (
                    <div key={img.id} style={{ margin:'0', position: 'relative' }}>
                        <img src={img.partImg} alt='' style={{ width: '145px' }}/>
                        <button type='button' onClick={() => handleDeletePartImg(id, img.id)} 
                            style={{ position: 'absolute', top: '5px',left: '5px', backgroundColor: 'red' }}
                        ><AiTwotoneDelete /></button>
                    </div>
                )) : 'nera foto'
            }
        </div>
    );
};

export default ShowPartGallery;