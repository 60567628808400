import React from 'react';
import { useUserContext } from '../../../../context/UserAuthContext';
import DashboardFrame from '../../../../components/DashboardFrame';
import D_Content from '../../../../components/DashboardFrame/D_Content';
import SingleCard from '../../../../components/DashboardFrame/D_Content/SingleCard';
import { Link, useParams } from 'react-router-dom';

// import { partsData } from './partsData';
// import { addDoc, collection } from 'firebase/firestore';
// import { FireDB } from '../../../../server/firebase-config';
// import NewOrderList from './NewOrderList';
import TestOrder from './TestOrder';
// import { FireDB } from '../../../../server/firebase-config';
// import { addDoc, collection, doc, getDoc, onSnapshot, orderBy, query, serverTimestamp } from 'firebase/firestore';
// import PartCard from './PartCard';

const CarPartsOrder = () => {
    const { user, logoutUser } = useUserContext();
    const { id } = useParams();
    // const [savedCarParts, setSavedCarParts] = useState({});

    // const [checkedState, setCheckedState] = useState(
    // new Array(partsData.length).fill(false)
    // );


    // const navigate = useNavigate();

    // const handleOnChange = (position) => {
    //     const updatedCheckedState = checkedState.map((item, index) =>
    //         index === position ? !item : item
    //     );
    //     setCheckedState(updatedCheckedState);

    //     let myArray = [];
  
    //     updatedCheckedState.reduce(
    //         (name, currentState, index) => {
    //             if (currentState === true) {
    //             myArray.push({name: partsData[index].name});
    //             return myArray;
    //             }
    //             // return 'please check box grumin';
    //         },
    //         ''
    //     );
    //     setSavedCarParts(myArray);
    // };

    // const handleOnSubmit = async (event) => {
    //     event.preventDefault();
    //     const partsCollectionRef = collection(FireDB, `cars/${id}/test`);
    //     await addDoc(partsCollectionRef, {savedCarParts});
    //     navigate(`/dashboard/cars/car_ordered_parts/${id}`);    
    // };


    
    return (
        <DashboardFrame
            user={user}
            logoutUser={logoutUser}
        >
            <D_Content title='Automobiliai'>
                <SingleCard  title='Detalės test'>
                    <h6>Auto id: {id}</h6>
                    <Link to={`/dashboard/cars/car_ordered_parts/${id}`}>Sąrašas</Link>
                </SingleCard>

                <SingleCard title='Rezervuoti detales'>
                    <TestOrder id={id} />
                </SingleCard>
                
                {/* <SingleCard title='naujas check sarasas'>
                    <NewOrderList id={id} />

                </SingleCard> */}

                {/* <SingleCard  title='Sąrašas'>
                <form onSubmit={handleOnSubmit}>
                    <ul>
                        {
                            partsData.map(({ name }, index) => {
                                return (
                                    <li key={index} className='checkPart-box'>
                                        <input
                                            type='checkbox'
                                            id={`custom-checkbox-${index}`}
                                            name={name}
                                            value={name}
                                            checked={checkedState[index]}
                                            onChange={() => handleOnChange(index)}
                                        />
                                        <label htmlFor={`custom-checkbox-${index}`}>{name}</label>
                                    </li>
                                );
                            })
                        }   
                        <button type='submit' className='checkbox-submit-button'>Pridėti į sąrašą</button>
                    </ul>
                </form>
               
                </SingleCard> */}
            </D_Content>
        </DashboardFrame>
    );
};

export default CarPartsOrder;




//     {
//       name:'Variklis benzininis',
//       units:[1, 2, 3, 4, 5, 6, 7, 8, 9]
//     },
//     {
//       name:'Variklis dyzelinis',
//       units: ''
//     },
//     {
//       name:'Variklis dujinis',
//       units: ''
//     },
//     {
//       name:'Variklis hibridas-elektrinis'
//     },
//     {
//       name:'Automatinė pavarų dėžė'
//     },
//     {
//       name:'Mechaninė pavarų dėžė'
//     },
//     {
//       name:'Pavarų dėžės reduktorius'
//     },
//     {
//       name:'ABS blokas'
//     },
//     {
//       name:'Akseleratoriaus pedalas'
//     },
//     {
//       name:'Alyvos aušintuvas'
//     },
//     {
//       name:'Alyvos karterio apsauga'
//     },
//     {
//       name:'Amortizatorius'
//     },
//     {
//       name:'Antena'
//     },
//     {
//       name:'Atstumo daviklis'
//     },
//     {
//       name:'Aušinamojo skysčio bakelis'
//     },
//     {
//       name:'Aušinimo šlangos'
//     },
//     {
//       name:'Aušinimo radiatorius'
//     },
//     {
//       name:'Aušinimo ventiliatorius'
//     },
//     {
//       name:'Bagažinės dangtis'
//     },
//     {
//       name:'Bagažinės dangčio užraktas'
//     },
//     {
//       name:'Bagažinės dangčio spyruoklės'
//     },
//     {
//       name:'Bagažinės užuolaida štorkė'
//     },
//     {
//       name:'Bagažinės dangčio dujiniai amortizatoriai/stumokliukai'
//     },
//     {
//       name:'Baterija'
//     },
//     {
//       name:'Buferis'
//     },
//     {
//       name:'Buferio laikikliai'
//     },
//     {
//       name:'Buferio stiprintuvas'
//     },
//     {
//       name:'Buferio apdailos dalys'
//     },
//     {
//       name:'CD keitiklis'
//     },
//     {
//       name:'CD grotuvas'
//     },
//     {
//       name:'Centrinė konsole'
//     },
//     {
//       name:'Daiktadėžė'
//     },
//     {
//       name:'Dugno apsauginė apdaila'
//     },
//     {
//       name:'Durys'
//     },
//     {
//       name:'Durų valdymo modulis'
//     },
//     {
//       name:'Durų rankena'
//     },
//     {
//       name:'Durų vyriai'
//     },
//     {
//       name:'Durų užraktas'
//     },
//     {
//       name:'Durų sandarinimo guma'
//     },
//     {
//       name:'Durų apmušimas/Panelė'
//     },
//     {
//       name:'Durų panelė'
//     },
//     {
//       name:'Komplektas detalių nuo durų CAR'
//     },
//     {
//       name:'Duslintuvas'
//     },
//     {
//       name:'Farkopas'
//     },
//     {
//       name:'Galinė važiuoklė'
//     },
//     {
//       name:'Galinis buferis'
//     },
//     {
//       name:'Galinio buferio grotelės'
//     },
//     {
//       name:'Galinio buferio stiprintuvas'
//     },
//     {
//       name:'Galinis žibintas'
//     },
//     {
//       name:'Galinis reduktroius'
//     },
//     {
//       name:'Galinio vaizdo veidrodėlis'
//     },
//     {
//       name:'Garsiakalbis'
//     },
//     {
//       name:'Žemu dažnių garsiakalbis su stiprintuvu'
//     },
//     {
//       name:'Garso kolonelė'
//     },
//     {
//       name:'Groteles'
//     },
//     {
//       name:'Guoliavietė'
//     },
//     {
//       name:'Interkuliario šlanga'
//     },
//     {
//       name:'Intercoolerio radiatorius'
//     },
//     {
//       name:'Jungtukas'
//     },
//     {
//       name:'Kardaninis velenas'
//     },
//     {
//       name:'Kilimeliai'
//     },
//     {
//       name:'Klimatronikas su magnetola'
//     },
//     {
//       name:'Klimato kontrolės modulis'
//     },
//     {
//       name:'AC/Kondicionierius kompresosius'
//     },
//     {
//       name:'AC /Kondicionieriaus šlanga'
//     },
//     {
//       name:'AC /Kondicionieriaus vamzdeliai'
//     },
//     {
//       name:'Konsole'
//     },
//     {
//       name:'Kilimas'
//     },
//     {
//       name:'Kuro siurblys'
//     },
//     {
//       name:'Lango pakelimo mechnaizmas'
//     },
//     {
//       name:'Langu apiplovimo skysčio bakelis'
//     },
//     {
//       name:'Lubos'
//     },
//     {
//       name:'Magnetola'
//     },
//     {
//       name:'Oro filtro deze'
//     },
//     {
//       name:'Oro paemimo slanga'
//     },
//     {
//       name:'Ortakis'
//     },
//     {
//       name:'Padangos remonto komplektas'
//     },
//     {
//       name:'WEBASTO/Papildomas sildytuvas WEBASTO'
//     },
//     {
//       name:'Pavaru perjungimo mechanizmo lynas'
//     },
//     {
//       name:'Pavaru perjungimo mechanizmas'
//     },
//     {
//       name:'Pavaru dezes ausintuvas/radiatorius'
//     },
//     {
//       name:'Pedaline'
//     },
//     {
//       name:'Pedalai'
//     },
//     {
//       name:'Prietaisu panele'
//     },
//     {
//       name:'Prietaisu paneles remas'
//     },
//     {
//       name:'Prietaisu skydelis'
//     },
//     {
//       name:'porankis'
//     },
//     {
//       name:'Posukio žibintas'
//     },

//     {
//       name:'Posukiu jungiklis'
//     },
//     {
//       name:'Posparnis'
//     },
//     {
//       name:'Priekinio buferio apsauginis lankas'
//     },
//     {
//       name:'Priesrukinis zibintas'
//     },
//     {
//       name:'Priekinis traversas'
//     },
//     {
//       name:'Priekine vaziuokle'
//     },
//     {
//       name:'Priekinis buferis'
//     },
//     {
//       name:'Priekinio buferio stiprintuvas'
//     },
//     {
//       name:'Priekines groteles'
//     },
//     {
//       name:'Priekinis zibintas'
//     },
//     {
//       name:'Priekinis reduktorius'
//     },
//     {
//       name:'Purvasaugiai'
//     },
//     {
//       name:'Lock carrier with mounting for radiators/Radiatoriu panele'
//     },
//     {
//       name:'Parts from Lock carrier with mounting for radiators/Radiatoriu paneles dalys'
//     },
//     {
//       name:'Handbrake/Rankinis stabdis'
//     },
//     {
//       name:'Spare wheel/Atsarginis ratas'
//     },
//     {
//       name:'Rims with used car tyres/Ratlankiai su naudotomis padangomis'
//     },
//     {
//       name:'Wheel change set/Rato pakeitimo komplektas'
//     },
//     {
//       name:'Interior trim/Salono apdaila'
//     },
//     {
//       name:'Interrior light/Salono apsvietimo zibintas'
//     },
//     {
//       name:'Heat unit evaporator with frame/Salono sildymo sistema su remu'
//     },
//     {
//       name:'Blower motor/Salono ventiliatorius'
//     },

//     {
//       name:'Heat unit evaporator/Salono sildymo sistema'
//     },
//     {
//       name:'Heat unit evaporator/Salono sildymo sistema'
//     },
//     {
//       name:'Seat belt/Saugos dirzas'
//     },
//     {
//       name:'Seat belt lock/Saugos dirzo uzraktas'
//     },
//     {
//       name:'Fender/Sparnas'
//     },
//     {
//       name:'Springs/Spyruokles'
//     },
//     {
//       name:'Fluid tank/Skyscio bakelis'
//     },
//     {
//       name:'Roof rails/Stogo bagazines begiai'
//     },
//     {
//       name:'Seat/Sedyne'
//     },
//     {
//       name:'Seats set/Sedyniu komplektas'
//     },
//     {
//       name:'Side member/Slenkstis'
//     },
//     {
//       name:'Side member trim/Slenksciu apdaila'
//     },
//     {
//       name:'Stabilizer/Stabilizatorius'
//     },
//     {
//       name:'Brake calipper/Stabdziu apkaba'
//     },
//     {
//       name:'Brake servo/Stabdziu stiprintuvas'
//     },
//     {
//       name:'Sunroof/Stoglangis/Liukas'
//     },
//     {
//       name:'Heater control module/Sildytuvo valdymo modulis'
//     },
//     {
//       name:'Light switch/Sviesu jungiklis'
//     },
//     {
//       name:'Cross panel/Tarpine panele/skydas'
//     },
//     {
//       name:'Steering wheel/Vairas'
//     },
//     {
//       name:'Steering column/Vairo stebule'
//     },
//     {
//       name:'Steering rack/Vairo kolonele'
//     },

//     {
//       name:'Power steering fluid tank/Vairo stiprintuvo skyscio bakelis'
//     },
//     {
//       name:'Vacum box/Vakumine deze'
//     },
//     {
//       name:'Wiper /Valytuvas'
//     },
//     {
//       name:'Wiper mechanism/Valytuvu mechanizmas'
//     },
//     {
//       name:'Wiper mechanism trim/Valytuvu mechanizmo apdaila'
//     },
//     {
//       name:'Wiper motor /Valytuvo varikliukas'
//     },
//     {
//       name:'Control module/Valdymo modulis'
//     },
//     {
//       name:'Engine bonnet/Variklio gaubtas'
//     },
//     {
//       name:'Engine bonnet lock/Variklio gaubto uzraktas'
//     },
//     {
//       name:'Engine bonnet hinges/Variklio gaubto vyriai'
//     },
//     {
//       name:'Engine bonnet lock cable/Variklio gaubto uzrakto lynas'
//     },
//     {
//       name:'Engine compartment trims/Variklio skyriaus apdaila'
//     },
//     {
//       name:'Engine cover/Variklio dangtelis-apdaila'
//     },
//     {
//       name:'Engine bonnet gas strut/Variklio gaubto dujuniai amortizatoriai/stumokliukai'
//     },
//     {
//       name:'Hinge/Vyris'
//     },
//     {
//       name:'Ignition lock/Uzvedimo spynele'
//     },
//     {
//       name:'Lock/Uzraktas'
//     },
//     {
//       name:'Car Parts / Detalės - Patvirtinti pasirinkimą , šitą būtina pažymėti !'
//     }
//   ];