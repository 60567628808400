import React from 'react';
import { Link } from 'react-router-dom';
import Frame from '../../../components/Frame';

const PageNotFound = () => {
    return (
        <Frame>
            <div className='page-not-found'>
                <h2>
                    <span>Puslapis nerastas.</span>
                    <Link to='/' className='link'>Grįžkite į pagrindinį puslapį.</Link>
                </h2>
                
            </div>
        </Frame>
    );
};

export default PageNotFound;