import { onSnapshot, query } from 'firebase/firestore';
import React, { useState } from 'react';
import { useEffect } from 'react';
import * as FaIcons from 'react-icons/fa';
import * as GiIcons from 'react-icons/gi';
import { packingsListsCollectionRef, carsCollectionRef } from '../../../../services/collectionsRef';

const DashboardCards = () => {
    const [ packinglists, setPackinglists ] = useState([]);
    const [ cars, setCars ] = useState([]);

    useEffect(() => {
        const fetchPLData = query(packingsListsCollectionRef);
        const onsub = onSnapshot(fetchPLData, (snapshot) => {
            setPackinglists(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id})));
        });
        onsub;
    }, []);

    useEffect(() => {
        const fetchCarsData = query(carsCollectionRef);
        const onsub = onSnapshot(fetchCarsData, (snapshot) => {
            setCars(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id})));
        });
        onsub;
    }, []);

    return (
        <ul className='dashboardCards'>
             <li className='d-card'>
                <div className='info'>
                    <span className='number'>{packinglists.length > 0 ? packinglists.length : 0}</span>
                    <span className='title'>Packinglist{packinglists.length > 0 ? 'ai' : 'ų'}</span>
                </div>
                <div className='icon'>
                    <FaIcons.FaReceipt  />
                </div>
            </li>
            <li className='d-card'>
                <div className='info'>
                    <span className='number'>{cars.length > 0 ? cars.length : 0}</span>
                    <span className='title'>Automobili{packinglists.length > 0 ? 'ai' : 'ų'}</span>
                </div>
                <div className='icon'>
                    <FaIcons.FaCar />
                </div>
            </li>
            <li className='d-card'>
                <div className='info'>
                    <span className='number'>0</span>
                    <span className='title'>Detalių</span>
                </div>
                <div className='icon'>
                    <GiIcons.GiGears  />
                </div>
            </li>
            <li className='d-card'>
                <div className='info'>
                    <span className='number'>0</span>
                    <span className='title'>Klientų</span>
                </div>
                <div className='icon'>
                    <FaIcons.FaUsers   />
                </div>
            </li>
        </ul>
    );
};

export default DashboardCards;