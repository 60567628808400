import React, { useState, useEffect } from 'react';
import { useUserContext } from '../../../../context/UserAuthContext';
import { Link, useParams } from 'react-router-dom';
import { FireDB } from '../../../../server/firebase-config';
import { collection, doc, getDoc, onSnapshot, query } from 'firebase/firestore';
import DashboardFrame from '../../../../components/DashboardFrame';
import D_Content from '../../../../components/DashboardFrame/D_Content';
import SingleCard from '../../../../components/DashboardFrame/D_Content/SingleCard';
import { AiOutlineCloseSquare } from 'react-icons/ai';

const CarPage = () => {
    const { user, logoutUser } = useUserContext();
    const { id } = useParams();
    const [car, setCar] = useState({});
    const [images, setImages] = useState([]);
    const [selectedImg, setSelectedImg] = useState(0);
    const [showImgOnBigScreen, setShowImgOnBigScreen] = useState(false);
 

    const docRef = doc(FireDB, "cars", id);
    
    useEffect(() => {
        async function fetchData() {
            const response = await getDoc(docRef);
            try{
                setCar(response.data());
            } catch(err) {
                alert(err);
            }
        }
        fetchData();
    }, [id]);

    const imagesCollectionRef = collection(FireDB, `cars/${id}/images`);

    useEffect(() => {
        if(id) {
            const fetchImagesData = query(imagesCollectionRef);
            const onsub = onSnapshot(fetchImagesData, (snapshot) => {
                setImages(snapshot.docs.map((doc) => ({id: doc.id, ...doc.data()})));
            });
            return onsub;
        }
    }, [id]);

    useEffect(() =>  {
        if (images.length > 0){
            setSelectedImg(images[0].imgName);
        }
    },[images]);

    //images carousel
    const [current, setCurrent] = useState(0);

    function nextSlide() {
        setCurrent(current === images.length - 1 ? 0 : current + 1);
    }
    
    function prevSlide() {
        setCurrent(current === 0 ? images.length - 1 : current - 1);
    }

    console.log('current : ', current);
    console.log('selectedImg : ', selectedImg);

  
    return (
        <DashboardFrame
            user={user}
            logoutUser={logoutUser}
        >
            <D_Content title='Automobiliai'>
                <SingleCard  title='Automobilio info'>
                    <h1 className='car-title'>{car.brand} {car.model} {car.year} m.</h1>
                    <div className='carPage-container'>
                        <div className='car-images'>
                            <div className='albums-App'>
                                <div className='albums-app-container' >
                                    <img src={selectedImg || 'http://grumin.lt/web-images/placeholder.jpg'} onClick={()=> setShowImgOnBigScreen(!showImgOnBigScreen)} alt='albums-selected' className='albums-selected' />
                                   
                                    <div className='albums-imgContainer'>
                                    {
                                        images.length > 0 && 
                                        images.map((img, index) => (
                                            <img 
                                                src={img.imgName} 
                                                alt='dog' 
                                                key={index} 
                                                style={{ border: selectedImg === img.imgName && '2px solid #d7bfa1' }} 
                                                onClick={() => setSelectedImg(img.imgName)}
                                            />
                                        ))
                                    }
                                    </div>
                                </div>
                                {
                                        showImgOnBigScreen &&
                                        <div className='show-big-image'>
                                            <div className='img-container'>
                                                <button type='button' onClick={()=> setShowImgOnBigScreen(!showImgOnBigScreen)}><AiOutlineCloseSquare /></button>
                                               
                                                {/* <img src={selectedImg} alt='car album' />  */}

                                                <div className='slider'>
                                                    <div className='left-arrow' onClick={prevSlide}>
                                                    ⬅
                                                    </div>
                                                    <div className='right-arrow' onClick={nextSlide}>
                                                    ⮕
                                                    </div>
                                                    {images.map(
                                                    (image, index) =>
                                                        current === index && (
                                                        <div key={image} className='slide'>
                                                            <img src={image.imgName} alt='images' />
                                                        </div>
                                                        )
                                                    )}
                                                </div>



                                                {/* <a href={selectedImg} rel='noreferrer'>img link</a> */}
                                            </div>
                                        </div>
                                    }
                            </div>
                        </div>
                        <div className='car-info'>
                            <ul>
                                <li><span>Anr:</span> <h3>{car.carIdNr}</h3></li>
                                <li><span>Gamintojas:</span> <h3>{car.brand}</h3></li>
                                <li><span>Modelis:</span> <h3>{car.model}</h3></li>
                                <li><span>Metai:</span> <h3>{car.year}</h3></li>
                                <li><span>Variklis:</span> <h3>{car.fuelType}</h3></li>
                                <li><span>Darbinis tūris:</span> <h3>{car.engineWorkingVolume}</h3></li>
                                <li><span>Kodas:</span> <h3>{car.engineCode}</h3></li>
                                <li><span>Kw:</span> <h3>{car.engineKw}</h3></li>
                                <li><span>Ar buvo užvestas ?</span> <h3>{car.engineWorking}</h3></li>
                                <li><span>Pavarų dėžė:</span> <h3>{car.gearBoxType}</h3></li>
                                <li><span>Pavarų dėžės kodas:</span> <h3>{car.gearBoxCode}</h3></li>
                                <li><span>Rida:</span> <h3>{car.km} km</h3></li>
                                <li><span>Reg.nr:</span> <h3>{car.carRegNr}</h3></li>
                                <li><span>Chasi.nr:</span> <h3>{car.chassiNr}</h3></li>
                                <li><span>Svoris:</span> <h3>{car.weight} kg </h3></li>
                                <li><span>Spalva:</span> <h3>{car.carColor}</h3></li>
                                <li><span>Vairo padėtis:</span> <h3>{car.steeringWheelPosition}</h3></li>
                                <li><span>Pridėjo info:</span> <h3>{car.addedInfo}</h3></li>
                                <li><span>Kaina:</span> <h3>{car.carPrice} {car.priceInCurrency}</h3></li>
                                <li><span>Statusas:</span> <h3>{car.carStatus}</h3></li>
                                <li><span>Klientas:</span> <h3>{car.carCustomer}</h3></li>
                                <li><span>Komentarai:</span> <h3>{car.carComments}</h3></li>
                                <li><span>Data:</span> <h3>{car.date}</h3></li>
                                <li><span>Atnaujinta:</span> <h3>{car.update}</h3></li>
                                <li><span>Mygtukas:</span> <h3>{car.showDeleteButton === 'true' ? 'true - auto info nesaugoma' : 'false - auto info saugoma'}</h3></li>
                                <li><h6>id: {id}</h6></li>
                            </ul>
                        </div>
                    </div>
                    <div className='carPage-links'>
                        <Link to='/dashboard/cars/' className='link' >Atgal</Link>
                        <Link to={`/dashboard/cars/update_car/${id}`} className='link'>Atnaujinti</Link>
                    </div>
                </SingleCard>
            </D_Content>
        </DashboardFrame>
    );
};

export default CarPage;